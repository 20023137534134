import * as React from 'react';
import { TextField, Stack, StackItem, css } from 'office-ui-fabric-react';
import WizardNavigationButtons from './StepOrderForm/WizardNavigationFooter';
import { IFormProps, IMaterial } from './Models';

export interface IMaterialSelectionFormProps extends IFormProps {
    isUserAuthenticated?: boolean;
    userAccount?: any;
    materialList: IMaterial[];
}

export interface IMaterialSelectionFormState {
    materialSelectionList: IMaterial[];
    errorState: {
        errorMessage: string | undefined,
        showError: boolean | undefined
    }[];
}

export default class MaterialSelectionForm extends React.Component<IMaterialSelectionFormProps, IMaterialSelectionFormState> {
    private allowUpdate = false;

    constructor(props: IMaterialSelectionFormProps) {
        super(props);

        const errorState = props.materialList.map(x => ({
            errorMessage: '',
            showError: false
        }));

        this.state = {
            materialSelectionList: props.materialList.map(x => x),
            errorState: errorState
        }
    }

    private setMaterialList = (materialList: IMaterial[]) => {
        var newMaterialList: IMaterial[] = materialList.map(x => x);

        this.setState({
            materialSelectionList: newMaterialList
        });
    }

    componentDidUpdate(prevProps: IMaterialSelectionFormProps, prevState: IMaterialSelectionFormState) {

        if (prevProps.order && this.props.order && this.props.order.materialList) {
            let update = true;
            if (!prevProps.order.materialList) {
                this.setMaterialList(JSON.parse(this.props.order.materialList ?? '[]'));
            }
            else {
                const prevMaterialList: IMaterial[] = JSON.parse(prevProps.order.materialList);
                const currentMaterialList: IMaterial[] = JSON.parse(this.props.order.materialList);

                // for (let index = 0; index < array.length; index++) {
                //     const element = array[index];
                // }
            }
        }

        if (this.allowUpdate) {
            this.allowUpdate = false;
            this.setMaterialList(JSON.parse(this.props?.order?.materialList ?? '[]'));
        }
    }

    private onChangeTextField = (rowIndex: number) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {

        const materialSelectionList = [...this.state.materialSelectionList];
        const errorState = { ...this.state.errorState };
        if (!newValue) {
            errorState[rowIndex] = {
                errorMessage: '',
                showError: false
            }
        }
        else {
            if (!isNaN(newValue as any)) {
                materialSelectionList[rowIndex].quantity = +newValue;
                errorState[rowIndex] = {
                    errorMessage: '',
                    showError: false
                }
            }
            else {
                errorState[rowIndex] = {
                    errorMessage: 'Only interger values are allowed.',
                    showError: true
                }
            }
        }

        this.setState({
            materialSelectionList: materialSelectionList,
            errorState: errorState
        });

        this.allowUpdate = true;
        this.props.updateFormData('materialList', JSON.stringify(materialSelectionList));
    }

    private goToNextStep = () => {
        this.props.nextStep && this.props.nextStep();
    };

    private renderHardwareTable = (materialSelectionList: IMaterial[], errorState: { errorMessage: string | undefined; showError: boolean | undefined; }[], isReadOnly: boolean) => {

        if (window.innerWidth < 768) {
            return this.renderListViewMaterialList(isReadOnly, materialSelectionList, errorState);
        }
        else {
            return this.renderTabularViewMaterialList(isReadOnly, materialSelectionList, errorState);
        }


    }

    private renderTabularViewMaterialList(isReadOnly: boolean, materialSelectionList: IMaterial[], errorState: { errorMessage: string | undefined; showError: boolean | undefined; }[]): JSX.Element {

        let tHead = <thead>
            <tr>
                <th>QTY</th>
                <th>MATERIAL NO.</th>
                <th>DESCRIPTION</th>
                <th>PRICING</th>
            </tr>
        </thead>;

        let tBody: any;

        if (isReadOnly) {
            tBody = (
                <tbody>
                    {materialSelectionList.map((material, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                <td className="text-right">{material.quantity}</td>
                                <td>{material.title}</td>
                                <td>{material.description}</td>
                                <td className="text-right">${material.pricing?.toLocaleString()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            );
        }
        else {
            tBody = (
                <tbody>
                    {materialSelectionList.map((material, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                <td className="text-center">
                                    <TextField
                                        styles={{
                                            root: {
                                                display: 'inline-block',
                                                maxWidth: '100px'
                                            }
                                        }}
                                        value={material.quantity?.toString() ?? ''}
                                        onChange={this.onChangeTextField(rowIndex)}
                                        errorMessage={errorState[rowIndex].errorMessage} />
                                </td>
                                <td>{material.title}</td>
                                <td>{material.description}</td>
                                <td className="text-right">${material.pricing?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                        );
                    })}
                </tbody>
            );
        }

        return (
            <table className="table-material">
                {tHead}
                {tBody}
            </table>
        );
    }

    private renderListViewMaterialList(isReadOnly: boolean, materialSelectionList: IMaterial[], errorState: { errorMessage: string | undefined; showError: boolean | undefined; }[]): JSX.Element {

        let listEntries: any;

        if (isReadOnly) {
            listEntries = (
                <>
                    {materialSelectionList.map((material, rowIndex) => {
                        return (
                            <li key={rowIndex}>
                                <div>
                                    <span>QTY</span>
                                    <span>:</span>
                                    <span>{material.quantity}</span>
                                </div>
                                <div>
                                    <span>MATERIAL NO.</span>
                                    <span>:</span>
                                    <span>{material.title}</span>
                                </div>
                                <div>
                                    <span>DESCRIPTION</span>
                                    <span>:</span>
                                    <span>{material.description}</span>
                                </div>
                                <div>
                                    <span>PRICING</span>
                                    <span>:</span>
                                    <span>${material.pricing?.toLocaleString()}</span>
                                </div>
                            </li>
                        );
                    })}
                </>
            );
        }
        else {
            listEntries = (
                <>
                    {materialSelectionList.map((material, rowIndex) => {
                        return (
                            <li key={rowIndex}>
                                <div>
                                    <span>QTY</span>
                                    <span>:</span>
                                    <TextField
                                        styles={{
                                            root: {
                                                marginLeft: '5px',
                                                display: 'inline-block',
                                                maxWidth: '80%'
                                            }
                                        }}
                                        value={material.quantity?.toString() ?? ''}
                                        onChange={this.onChangeTextField(rowIndex)}
                                        errorMessage={errorState[rowIndex].errorMessage} />
                                </div>
                                <div>
                                    <span>MATERIAL NO.</span>
                                    <span>:</span>
                                    <span>{material.title}</span>
                                </div>
                                <div>
                                    <span>DESCRIPTION</span>
                                    <span>:</span>
                                    <span>{material.description}</span>
                                </div>
                                <div>
                                    <span>PRICING</span>
                                    <span>:</span>
                                    <span>${material.pricing?.toLocaleString()}</span>
                                </div>
                            </li>
                        );
                    })}
                </>
            );
        }

        return (
            <div className="list-container-material">
                <ul className="list-material">
                    {listEntries}
                </ul>
            </div>

        );
    }

    render() {
        const props = this.props;
        const { errorState, materialSelectionList } = this.state;

        if (materialSelectionList && materialSelectionList.length)
            return (
                <Stack verticalAlign="space-between" className="fullWidth" horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                    <Stack className={css("subscriptionContainerInner", props.stepSizeClass)}>
                        <div>
                            <h4 className="stepHeading">Dental Monitoring™ Patient Hardware</h4>
                        </div>
                        {this.renderHardwareTable(materialSelectionList, errorState, props.isReadonly)}
                    </Stack>
                    {
                        props.showWizardNavigation ?
                            <StackItem className={css("wizardNavigationContainer", props.stepSizeClass)}>
                                <WizardNavigationButtons {...props} step={props.currentStep} nextStep={this.goToNextStep}></WizardNavigationButtons>
                            </StackItem>
                            : null
                    }
                </Stack>
            );
        else
            return null;
    }


}