export default class HttpService {

    public static async get(url: string, body?: any): Promise<any> {
        let response: any;

        if (body)
            response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            });
        else
            response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json'
                },
            });

        if (response.ok)
            return response.json();
        else
            throw await response.json();
    }

    public static async post(url: string, body: any): Promise<any> {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        if (response.ok)
            return response.json();
        else
            throw await response.json();
    }

}