import * as React from 'react';
import '../OrderForm.scss';
import { IDropdownOption, DialogType, Spinner, SpinnerSize, Dialog, Stack, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { IDataProvider } from '../../../dataprovider/IDataProvider';
import CompleteOrderForm from './CompleteOrderForm';
import SuccessBox from '../StepOrderForm/SuccessBox';
import { IOrder, IApplicationLabels, IApplicationSettings, IPaymentSettings, IPaymentTokenResponse } from '../Models';
import { Common } from '../../../dataprovider/Common';

export interface IOrderSummaryFormProps {
    order?: IOrder;
    dataProvider: IDataProvider;
    location: Location;
    history: History;
}

export interface IOrderSummaryFormState {
    showWaitingSpinner?: boolean;
    order?: IOrder;
    labels: IApplicationLabels | null;
    states?: IDropdownOption[];
    message?: string | JSX.Element | null;
    messageType?: MessageBarType;
    isReadOnly: boolean;
    showOrderSuccessMessage?: boolean;
    paymentSettings?: IPaymentSettings;
    paymentInfo?: any;
    paymentAccessTokenResponse?: IPaymentTokenResponse;
}

export class OrderSummaryForm extends React.Component<IOrderSummaryFormProps, IOrderSummaryFormState> {
    static displayName = OrderSummaryForm.name;

    constructor(props: any) {
        super(props);
        this.state = {
            order: {},
            showWaitingSpinner: true,
            labels: null,
            states: [],
            message: null,
            messageType: MessageBarType.success,
            isReadOnly: true,
            showOrderSuccessMessage: false
        }
    }

    async componentDidMount() {


        let query = new URLSearchParams((this.props as any).location.search);
        const orderId = query.get("ref")
        if (orderId) {

            const promiseArray: Promise<any>[] = [];
            promiseArray.push(this.props.dataProvider.getOrder(orderId));


            Promise.all(promiseArray).then(async (result: any[]) => {
                const order: IOrder = result[0];
                let applicationSettings: IApplicationSettings;

                if (this.props.dataProvider.getApplicationSettings && order.country) {
                    applicationSettings = await this.props.dataProvider.getApplicationSettings(order.country);

                    const states = this.props.dataProvider.getStatesLists(order.country);
                    states.sort();

                    this.setState({
                        states: states.map(state => ({ key: state, text: state }))
                    });

                    Common.loadScript(`${applicationSettings.paymentSettings.paymentURL}/sdk/sdk.bundle.js`, () => {
                        this.setState({
                            showWaitingSpinner: false,
                            labels: applicationSettings.applicationLabels,
                            paymentSettings: applicationSettings.paymentSettings,
                            order: order,
                            isReadOnly: order && order.orderStatus && order.orderStatus === "Completed" ? true : false
                        });
                    });
                }

            });


        }

    }

    private updateForm = (key: string, value: any) => {
        const order = { ...this.state.order };

        (order as any)[key] = value;
        this.setState({
            order,
        });
    };

    private updateFormProperties = (properties: { key: string, value: any }[]) => {
        const order = { ...this.state.order };

        for (const property of properties) {
            (order as any)[property.key] = property.value;
        }

        this.setState({
            order,
        });
    };

    private setPaymentAccessToken = (accessTokenResponse: IPaymentTokenResponse) => {
        this.setState({
            paymentAccessTokenResponse: accessTokenResponse
        });
    }

    private setPaymentInfo = (paymentInfo: any) => {
        this.setState({
            paymentInfo: paymentInfo
        });
    }

    private onSubmitOrder = () => {
        this.setState({
            showWaitingSpinner: true
        })

        if (this.state.order) {
            const order = { ...this.state.order };
            order.orderStatus = "Completed";

            this.props.dataProvider.submitSignedOrder(order).then((result: any) => {
                this.hideSpinnerAndShowSuccessMessage("Order submitted successfully. Please check your mail for confirmation.");
            }).catch(error => {
                this.hideSpinnerAndShowErrorMessage(error, "_onClickSavePricingAppAdmin");
            });
        }

    }

    private hideSpinnerAndShowSuccessMessage = (message: string) => {
        this.setState({
            message: message,
            messageType: MessageBarType.success,
            showWaitingSpinner: false,
            showOrderSuccessMessage: true
        });
    }

    private hideSpinnerAndShowErrorMessage = (error: any, errorAreaName: string) => {

        this.props.dataProvider.logErrorInConsole?.(errorAreaName, error);

        this.setState({
            message: this.props.dataProvider.extractErrorMessageFromErrorObject?.(error),
            messageType: MessageBarType.error,
            showWaitingSpinner: false,
            showOrderSuccessMessage: false
        });
    }

    render() {
        const { showOrderSuccessMessage, message, messageType, states, isReadOnly,
            showWaitingSpinner, labels, order, paymentAccessTokenResponse, paymentInfo, paymentSettings } = this.state;

        const { location, history } = this.props;

        return (
            <React.Fragment>
                <Stack className="fullWidth" tokens={{ childrenGap: 20 }} verticalAlign="space-between" horizontalAlign="center" >
                    {
                        showOrderSuccessMessage ?
                            <SuccessBox location={location} history={history} message={String(message)} stepSizeClass="subscriptionContainerInnerWidth50"></SuccessBox>
                            :
                            <React.Fragment>
                                {
                                    message ?
                                        <MessageBar
                                            onDismiss={() => {
                                                this.setState({
                                                    message: null
                                                });
                                            }}
                                            messageBarType={messageType}
                                            isMultiline={true}
                                            truncated={true}
                                        >
                                            {message}
                                        </MessageBar>
                                        : null
                                }
                                <CompleteOrderForm
                                    isReadOnly={isReadOnly}
                                    updateFormData={this.updateForm}
                                    updateFormProperties={this.updateFormProperties}
                                    labels={labels}
                                    order={order}
                                    onSubmitOrder={this.onSubmitOrder}
                                    states={states ? states : []}
                                    dataProvider={this.props.dataProvider}
                                    setPaymentAccessToken={this.setPaymentAccessToken}
                                    setPaymentInfo={this.setPaymentInfo}
                                    paymentAccessTokenResponse={paymentAccessTokenResponse}
                                    paymentInfo={paymentInfo}
                                    paymentSettings={paymentSettings}
                                />
                                <Dialog
                                    hidden={!showWaitingSpinner}
                                    dialogContentProps={{
                                        type: DialogType.normal
                                    }}
                                    modalProps={{ isBlocking: true, className: "busyOverlay" }}
                                >
                                    <Spinner size={SpinnerSize.large} label="Working on it..." ariaLive="assertive" />
                                </Dialog>
                            </React.Fragment>
                    }

                </Stack>
            </React.Fragment>
        );
    }
}

