import * as React from 'react';
import { css, Checkbox, Stack, ICheckboxStyles, StackItem, Text } from 'office-ui-fabric-react';
import WizardNavigationButtons from './WizardNavigationFooter';
import { IFormProps } from '../Models';
import { Link } from 'react-router-dom';

export interface ISelectSubscriptionProps extends IFormProps {

}

type Subscription = {
    [key: string]: {
        name: string | undefined;
        selected: boolean | undefined;
    }
}

const getInitialState = (props: ISelectSubscriptionProps): Subscription => {
    return {
        DenToGoVirtualCheck: {
            name: props.labels?.dtgSubscriptionVirtualCheck,
            selected: props.labels && props.order && props.order.subscriptions ? props.order.subscriptions.indexOf(props.labels?.dtgSubscriptionVirtualCheck) >= 0 : false
        },
        DenToGoMonitoringLight: {
            name: props.labels?.dtgSubscriptionMonitoringLight,
            selected: props.labels && props.order && props.order.subscriptions ? props.order.subscriptions.indexOf(props.labels?.dtgSubscriptionMonitoringLight) >= 0 : false
        }
    };
}

const checkboxStyles: ICheckboxStyles = {
    label: {
        fontWeight: 'bold'
    }
}

const SelectSubscription = (props: ISelectSubscriptionProps) => {
    let html: JSX.Element = <React.Fragment></React.Fragment>;
    const labels = props.labels;
    const [subscriptions, setSubscriptions] = React.useState(getInitialState(props));
    const [showError, setShowError] = React.useState(false);
    const country = sessionStorage.getItem('Country');

    if (country) {
        const onChangeCheckBox = (key: string, name: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
            const newSubscription: Subscription = {
                ...subscriptions,
                [key]: {
                    name: name,
                    selected: checked
                }
            };

            let selectedSubscriptions = [];

            for (const key in newSubscription) {
                if (newSubscription.hasOwnProperty(key)) {
                    if (newSubscription[key] && newSubscription[key].selected) {
                        selectedSubscriptions.push(newSubscription[key].name);
                    }

                }
            }

            if (selectedSubscriptions.length)
                setShowError(false);
            else
                setShowError(true);

            setSubscriptions(newSubscription)

            props.updateFormData('subscriptions', selectedSubscriptions);
        }

        const validateSubscription = () => {
            let anyOptionSelected = false;
            for (const key in subscriptions) {
                if (subscriptions.hasOwnProperty(key)) {
                    if (subscriptions[key] && subscriptions[key].selected) {
                        anyOptionSelected = true;
                        break;
                    }

                }
            }

            if (anyOptionSelected) {
                setShowError(false);
                props.nextStep && props.nextStep();
            }
            else {
                setShowError(true);
            }
        };



        if (labels)
            html = (
                <Stack verticalAlign="space-between" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
                    <Stack className={css("subscriptionContainerInner", props.stepSizeClass)} tokens={{ childrenGap: 5 }}>
                        <div>
                            <h4 className="stepHeading">Please choose Subscriptions</h4>
                        </div>
                        <Checkbox
                            styles={checkboxStyles}
                            label={labels?.dtgSubscriptionVirtualCheck}
                            checked={subscriptions.DenToGoVirtualCheck.selected}
                            onChange={onChangeCheckBox('DenToGoVirtualCheck', labels?.dtgSubscriptionVirtualCheck)}
                        />
                        <div className="subscriptionDetails">
                            <b>{labels?.dtgSubscriptionVCDescriptionLabel1}</b>
                            <span>{labels?.dtgSubscriptionVCDescriptionLabel2}</span>
                        </div>
                        <Checkbox
                            styles={checkboxStyles}
                            label={labels?.dtgSubscriptionMonitoringLight}
                            checked={subscriptions.DenToGoMonitoringLight.selected}
                            onChange={onChangeCheckBox('DenToGoMonitoringLight', labels?.dtgSubscriptionMonitoringLight)}
                        />
                        <div className="subscriptionDetails">
                            <b>{labels?.dtgSubscriptionMLDescriptionLabel1}</b>
                            <span>{labels?.dtgSubscriptionMLDescriptionLabel2}</span>
                        </div>
                        {
                            showError ?
                                <div>
                                    <span className="errorText">Please select atleast one Subscription.</span>
                                </div> : null
                        }
                    </Stack>
                    {
                        props.showWizardNavigation ?
                            <StackItem className={css("wizardNavigationContainer", props.stepSizeClass)}>
                                <WizardNavigationButtons {...props} step={props.currentStep} nextStep={validateSubscription}></WizardNavigationButtons>
                            </StackItem>
                            : null
                    }
                </Stack>
            );
    }
    else {
        html = <Text variant="medium">Country not specified. Click <Link to={"/"}>here</Link> to start again</Text>;
    }


    return html;
}

export default SelectSubscription;