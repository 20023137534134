import * as React from 'react';
import './Layout.scss';
import logo from '../../images/str-group-white.svg';
import { Link } from 'react-router-dom';

import { Stack } from 'office-ui-fabric-react';

export class Layout extends React.Component<any, any> {
    static displayName = Layout.name;
    private year = new Date().getFullYear();

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Stack className="fullWidth">
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="header">
                    <div className="appName"><a onClick={() => { window.location.href = window.location.origin; }}>Dental Monitoring™</a></div>
                    <div className="companyLogoContainer"><img className="companyLogo" src={logo} alt="Straumann Logo"></img></div>
                </Stack>
                <div className="mainContent">
                    {/* <Stack className="fullWidth"> */}
                    {/* <h2 className="pageHeading">Service Agreement</h2> */}
                    <div className="container">{this.props.children}</div>
                    {/* </Stack> */}

                </div>
                <Stack horizontal horizontalAlign="start" verticalAlign="center" className="footer">
                    © {this.year} Institut Straumann AG
                </Stack>
            </Stack>
        );
    }
}
