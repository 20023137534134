import * as React from 'react';
import { css, Stack, StackItem, IDropdownOption, ActionButton } from 'office-ui-fabric-react';
import CustomerInformationForm from '../CustomerInformationForm';
import CustomerAgreementForm from '../CustomerAgreementForm';
import { IDataProvider } from '../../../dataprovider/IDataProvider';
import { IApplicationLabels, IOrder, IPaymentSettings, IPaymentTokenResponse } from '../Models';
import MaterialSelectionForm from '../MaterialSelectionForm';

export interface ICompleteOrderFormProps {
    isReadOnly: boolean;
    states: IDropdownOption[];
    labels: IApplicationLabels | null;
    order?: IOrder | null;
    onSubmitOrder: () => any | void | Promise<any>;
    updateFormData: (key: string, value: any) => void;
    updateFormProperties: (properties: { key: string, value: any }[]) => void;
    dataProvider: IDataProvider;
    paymentSettings?: IPaymentSettings | undefined;
    paymentInfo?: any;
    paymentAccessTokenResponse?: IPaymentTokenResponse;
    setPaymentAccessToken: (accessTokenResponse: IPaymentTokenResponse) => void;
    setPaymentInfo: (paymentInfo: any) => void;
}

const CompleteOrderForm = (props: ICompleteOrderFormProps) => {
    const {
        order, labels, paymentInfo, paymentSettings, paymentAccessTokenResponse,
        isReadOnly, states, setPaymentInfo, setPaymentAccessToken, updateFormData, updateFormProperties,
        dataProvider
    } = props;

    const customerInfoFormRef: React.Ref<CustomerInformationForm> = React.createRef<CustomerInformationForm>();
    const customerAgreementFormRef: React.Ref<CustomerAgreementForm> = React.createRef<CustomerAgreementForm>();

    const submitOrder = () => {
        const validateCustomerInfo = customerInfoFormRef.current?.validateCustomerInfo();
        const validateSignaturesAndTerms = customerAgreementFormRef.current?.validateSignaturesAndTerms();
        const validatePaymentDetails = customerAgreementFormRef.current?.validatePaymentDetails();

        if (validateCustomerInfo && validateSignaturesAndTerms && validatePaymentDetails) {
            props.onSubmitOrder();
        }
    }

    return (
        <Stack verticalAlign="space-between" className="orderSummaryContainer" horizontalAlign="center" tokens={{ childrenGap: 20 }}>

            <StackItem align="start">
                <h2 className="pageHeading">Order Summary</h2>
            </StackItem>
            <Stack verticalAlign="space-between" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack className={css("subscriptionContainerInner", 'fullWidth')} tokens={{ childrenGap: 5 }}>
                    <Stack>
                        <label className="label">Subcriptions</label>
                        <ul className="subscriptionList">
                            {order?.subscriptions?.map((sub, index) => <li key={index}>{sub}</li>)}
                        </ul>
                    </Stack>
                    <Stack>
                        <label className="label">Subcription Options</label>
                        <ul className="subscriptionList">
                            {
                                order?.soVirtualCheck ? <li>{order?.soVirtualCheck}</li> : null
                            }
                            {
                                order?.soMonitoringLightStarterKit !== undefined && order?.soMonitoringLightStarterKit !== null && order?.soMonitoringLightStarterKit ? <li>{labels?.mlStarterKit}</li> : null
                            }
                            {
                                order?.soMonitoringLightTrainingOption ? <li>{order?.soMonitoringLightTrainingOption}</li> : null
                            }
                        </ul>
                    </Stack>
                </Stack>
            </Stack>
            <MaterialSelectionForm
                isReadonly={true}
                updateFormData={updateFormData}
                updateFormProperties={updateFormProperties}
                labels={labels}
                order={order}
                materialList={order?.materialList ? JSON.parse(order.materialList) : []}
                currentStep={3}
                showWizardNavigation={false}
                stepSizeClass="subscriptionContainerInnerWidth100"
            />
            <CustomerInformationForm
                isReadonly={isReadOnly}
                ref={customerInfoFormRef}
                updateFormData={updateFormData}
                updateFormProperties={updateFormProperties}
                labels={labels}
                order={order}
                states={states ? states : []}
                showWizardNavigation={false}
                stepSizeClass="subscriptionContainerInnerWidth100"
            />
            <CustomerAgreementForm
                isReadonly={isReadOnly}
                ref={customerAgreementFormRef}
                updateFormData={updateFormData}
                updateFormProperties={updateFormProperties}
                labels={labels}
                order={order}
                showWizardNavigation={false}
                stepSizeClass="subscriptionContainerInnerWidth100"
                dataProvider={dataProvider}
                paymentSettings={paymentSettings}
                setPaymentAccessToken={setPaymentAccessToken}
                setPaymentInfo={setPaymentInfo}
                paymentAccessTokenResponse={paymentAccessTokenResponse}
                paymentInfo={paymentInfo}
            />

            {
                !props.isReadOnly ?
                    <StackItem align="end">
                        <ActionButton className="straumannBlueButton" iconProps={{ iconName: 'Assign' }} onClick={submitOrder}>Submit Order</ActionButton>
                    </StackItem>
                    : null
            }



        </Stack>
    );

}

export default CompleteOrderForm;