import * as React from 'react';
import { css, Stack, } from 'office-ui-fabric-react';
import { Link } from 'react-router-dom';
import { PageURL } from '../../../App';

export interface ISuccessBoxProps {
    location: Location;
    history: History;
    stepSizeClass: string;
    message: string;
}

const SuccessBox = (props: ISuccessBoxProps) => {

    let refreshUrl: string | null = null;

    if (props.location.pathname.toLowerCase() === PageURL.CreateOrder.toLowerCase())
        refreshUrl = window.location.origin + PageURL.CreateOrder;
    else if (props.location.pathname.toLowerCase() === PageURL.CreateOrderAuthenticated.toLowerCase())
        refreshUrl = window.location.origin + PageURL.CreateOrderAuthenticated;

    return (
        <Stack horizontal verticalAlign="center" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
            <Stack horizontal horizontalAlign="center" className={css("subscriptionContainerInner", props.stepSizeClass)} tokens={{ childrenGap: 5 }}>
                <div className="successBox">
                    <h4 className="stepHeading">{props.message}</h4>
                    {
                        refreshUrl ?
                            <h4 className="stepSubHeading">Click <a className="link" onClick={() => {
                                window.location.href = window.location.origin;
                            }}>here</a> to create New Order.</h4>
                            :
                            <h4 className="stepSubHeading">Click <Link to={window.location.origin}>here</Link> to create New Order.</h4>
                    }

                </div>
            </Stack>

        </Stack>
    );
}

export default SuccessBox;