import * as React from "react";
import * as msal from "@azure/msal-browser";
import { Stack, PrimaryButton, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { fetchMsGraph, graphConfig, loginRequest, msalConfig } from './AuthenticationConfig';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Icon } from '@fluentui/react/lib/Icon';
import HttpService from "../../dataprovider/HttpService";

const LockIcon = () => <Icon style={{ marginRight: '5px', fontSize: '25px' }} iconName="LockSolid" />;

interface AuthenticatedComponentProps {

}

interface AuthenticatedComponentState {
    account: any | null,
    error: any | null,
    isUserAuthenticated: boolean
    isAuthenticating: boolean
}

export default (AuthenticatedComponent: any, componentProps: any) =>
    class AuthProvider extends React.Component<AuthenticatedComponentProps, AuthenticatedComponentState> {
        private useRedirectForAuthentication = true;
        private msalInstance: any;

        constructor(props: AuthenticatedComponentProps) {
            super(props);

            this.state = {
                account: null,
                error: null,
                isUserAuthenticated: false,
                isAuthenticating: true,
            };

            console.log("AuthenticatedComponentProps: ", props);

        }

        async componentDidMount() {
            let skipSignIn = false;

            console.log("componentDidMount: ");

            let msalConfigFromServer = await HttpService.get(`/api/AzureConnectivity`);

            let msalConfigUpdated = {
                ...msalConfig
            };

            msalConfigUpdated.auth = {
                clientId: msalConfigFromServer.clientId,
                authority: msalConfigFromServer.authority,
                navigateToLoginRequestUrl: msalConfig.auth.navigateToLoginRequestUrl
            };

            console.log("msalConfig: ", msalConfigUpdated);
            
            this.msalInstance = new msal.PublicClientApplication(msalConfigUpdated);

            await this.msalInstance.handleRedirectPromise().then(this.handleResponse).catch((err: any) => {
                console.error(err);
                if (err.errorCode === 'access_denied') {
                    skipSignIn = true;

                    this.setState({
                        account: null,
                        error: null,
                        isUserAuthenticated: false,
                        isAuthenticating: false,
                    })
                }
            });

            if (!skipSignIn) {
                let currentAccounts: any[];

                currentAccounts = this.msalInstance.getAllAccounts();

                if (currentAccounts === null || currentAccounts.length === 0) {
                    this.onSignIn(this.useRedirectForAuthentication);
                } else if (currentAccounts.length > 1) {
                    // Add choose account code here
                    console.warn("Multiple accounts detected.");
                } else if (currentAccounts.length === 1) {
                    const loginRequestNew = { ...loginRequest, account: currentAccounts[0] };

                    const tokenResponse = await this.acquireToken(loginRequestNew, this.useRedirectForAuthentication)
                        .catch(error => {
                            this.setState({
                                error: error.message
                            });
                        });

                    if (tokenResponse) {
                        const user = await fetchMsGraph(graphConfig.graphMeEndpoint,tokenResponse.accessToken);
                        this.setState({
                            account: user,
                            error: null,
                            isUserAuthenticated: true,
                            isAuthenticating: false,
                        })

                    }
                }
            }
        }

        private handleResponse = async (resp: any) => {

            if (resp !== null) {
                const loginRequestNew = { ...loginRequest, account: resp.account };
                const tokenResponse = await this.acquireToken(loginRequestNew, this.useRedirectForAuthentication)
                    .catch(error => {
                        this.setState({
                            error: error.message
                        });
                    });

                if (tokenResponse) {
                    const user = await fetchMsGraph(graphConfig.graphMeEndpoint,tokenResponse.accessToken);

                    this.setState({
                        account: user,
                        error: null,
                        isUserAuthenticated: true,
                        isAuthenticating: false,
                    })

                }
            } else {
                // need to call getAccount here?
                const currentAccounts = this.msalInstance.getAllAccounts();
                if (!currentAccounts || currentAccounts.length < 1) {
                    return;
                } else if (currentAccounts.length > 1) {
                    // Add choose account code here
                } else if (currentAccounts.length === 1) {
                    const loginRequestNew = { ...loginRequest, account: currentAccounts[0] };

                    const tokenResponse = await this.acquireToken(loginRequestNew, this.useRedirectForAuthentication)
                        .catch(error => {
                            this.setState({
                                error: error.message
                            });
                        });

                    if (tokenResponse) {
                        const user = await fetchMsGraph(graphConfig.graphMeEndpoint,tokenResponse.accessToken);

                        this.setState({
                            account: user,
                            error: null,
                            isUserAuthenticated: true,
                            isAuthenticating: false,
                        });

                    }
                }
            }
        }

        // acquireToken = async (request: any) => {
        //     // request.account = this.state.account;
        //     return this.msalInstance.acquireTokenSilent(request).catch((error: any) => {
        //         console.warn("silent token acquisition fails. acquiring token using redirect");
        //         if (error instanceof InteractionRequiredAuthError) {
        //             // fallback to interaction when silent call fails
        //             return this.msalInstance.acquireTokenPopup(request)
        //                 .then((tokenResponse: any) => {
        //                     console.log(tokenResponse);
        //                     return tokenResponse;
        //                 }).catch((error: any) => {
        //                     console.error(error);
        //                 });

        //         } else {
        //             console.warn(error);
        //         }
        //     });
        // }

        acquireToken = async (request: any, redirect: boolean) => {
            // request.account = this.state.account;
            return this.msalInstance.acquireTokenSilent(request).catch((error: any) => {
                console.warn("silent token acquisition fails. acquiring token using redirect");
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    if (redirect)
                        return this.msalInstance.acquireTokenRedirect(request)
                            .then((tokenResponse: any) => {
                                console.log(tokenResponse);
                                return tokenResponse;
                            }).catch((error: any) => {
                                console.error(error);
                            });
                    else
                        return this.msalInstance.acquireTokenPopup(request)
                            .then((tokenResponse: any) => {
                                console.log(tokenResponse);
                                return tokenResponse;
                            }).catch((error: any) => {
                                console.error(error);
                            });

                } else {
                    console.warn(error);
                }
            });
        }

        onSignIn = async (redirect: boolean) => {
            if (redirect) {
                return this.msalInstance.loginRedirect({
                    ...loginRequest
                });
            }

            const loginResponse = await this.msalInstance
                .loginPopup(loginRequest)
                .catch((error: any) => {
                    this.setState({
                        error: error.message
                    });
                });


            if (loginResponse) {
                const loginRequestNew = { ...loginRequest, account: loginResponse.account };

                const tokenResponse = await this.acquireToken(loginRequestNew, this.useRedirectForAuthentication).catch(error => {
                    this.setState({
                        error: error.message
                    });
                });

                if (tokenResponse) {
                    const user = await fetchMsGraph(graphConfig.graphMeEndpoint,tokenResponse.accessToken);

                    this.setState({
                        account: user,
                        error: null,
                        isUserAuthenticated: true,
                        isAuthenticating: false,
                    })
                }
            }
        }

        onSignOut() {
            this.msalInstance.logout();
        }

        render() {
            const { error, account, isUserAuthenticated, isAuthenticating } = this.state;

            if (isAuthenticating) {
                return (
                    <Stack className="loggingInContainer" horizontalAlign="center" verticalAlign="center" reversed={true} >
                        <span>Trying to Log-In</span>
                        <div className="loggingInLoader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </Stack>);
            }
            else {
                if (isUserAuthenticated) {
                    return (
                        <AuthenticatedComponent
                            {...this.props}
                            {...componentProps}
                            userAccount={account}
                            isUserAuthenticated={isUserAuthenticated}
                        />
                    );
                }
                else {
                    return (
                        <Stack className="fullWidth" verticalAlign="space-between" horizontalAlign="center">
                            {
                                error ?
                                    <MessageBar
                                        onDismiss={() => {
                                            this.setState({
                                                error: null
                                            });
                                        }}
                                        messageBarType={MessageBarType.error}
                                        isMultiline={true}
                                        truncated={true}
                                    >
                                        {error}
                                    </MessageBar>
                                    : null
                            }
                            <h3 className="signInLabel"><LockIcon />Please sign-in into your account to access this page.</h3>
                            <PrimaryButton
                                text="Sign In"
                                onClick={() => {
                                    this.onSignIn(true);
                                }}
                                allowDisabledFocus
                            />
                        </Stack>
                    );
                }
            }

        }
    };
