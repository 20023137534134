import * as React from 'react';
import { css, Checkbox, Stack, StackItem, TextField, IconButton, } from 'office-ui-fabric-react';
import WizardNavigationButtons from './StepOrderForm/WizardNavigationFooter';
import { Common } from '../../dataprovider/Common';
import SignaturePad from 'react-signature-canvas'
import { IDataProvider } from '../../dataprovider/IDataProvider';
import { IPaymentSettings, IOrder, CustomFieldTypes, ErrorState, IFormProps, PaymentSteps, IPaymentTokenResponse } from './Models';
import { Spinner, SpinnerSize } from '@fluentui/react';

// let DelegoRapidPay:any;

export interface ICustomerAgreementFormProps extends IFormProps {
    onSubmitOrder?: () => any | void | Promise<any>;
    setPaymentAccessToken: (accessTokenResponse: IPaymentTokenResponse) => void;
    setPaymentInfo: (paymentInfo: any) => void;
    dataProvider: IDataProvider;
    paymentSettings: IPaymentSettings | undefined;
    paymentInfo?: any;
    paymentAccessTokenResponse?: IPaymentTokenResponse;
}

export interface ICustomerAgreementFormState {
    signatures: string;
    signatureName: string;
    acceptTermCondition: boolean;
    acceptTermConditionAnother: boolean;
    showSignatureError: boolean;
    showAcceptTermConditionError: boolean;
    showAcceptTermConditionAnotherError: boolean;
    showPaymentDetailsNotFoundError: boolean;
    showPaymentSectionLoadingText: boolean;
}


const getInitialSignatureState = (props: ICustomerAgreementFormProps): string => {
    return props.order && props.order.signatures ? props.order.signatures : "";
}

export default class CustomerAgreementForm extends React.Component<ICustomerAgreementFormProps, ICustomerAgreementFormState> {

    private signaturePadRef: React.RefObject<SignaturePad>;
    private rapidPay: any;
    private tokenizeResponse: any;

    constructor(props: ICustomerAgreementFormProps) {
        super(props);
        this.state = {
            acceptTermCondition: props.order?.acceptTermCondition || false,
            acceptTermConditionAnother: props.order?.acceptTermConditionAnother || false,
            showAcceptTermConditionAnotherError: false,
            showSignatureError: false,
            showAcceptTermConditionError: false,
            signatures: getInitialSignatureState(props),
            signatureName: '',
            showPaymentDetailsNotFoundError: false,
            showPaymentSectionLoadingText: false
        };

        this.signaturePadRef = React.createRef<SignaturePad>();
    }

    async componentDidMount() {
        if (this.signaturePadRef.current)
            this.signaturePadRef.current.fromDataURL(this.state.signatures);
        if (!this.props.isReadonly)
            await this.initialiseDelegoPaymentWindow();
    }

    async componentDidUpdate(prevProps: ICustomerAgreementFormProps, prevState: ICustomerAgreementFormState) {
        if (JSON.stringify(prevProps.order) !== JSON.stringify(this.props.order)) {
            this.setState({
                acceptTermCondition: this.props.order?.acceptTermCondition || false,
                acceptTermConditionAnother: this.props.order?.acceptTermConditionAnother || false,
                signatures: getInitialSignatureState(this.props),
            });
        }

        if (!this.props.isReadonly && !prevProps.paymentSettings && this.props.paymentSettings) {
            await this.initialiseDelegoPaymentWindow();
        }
    }

    private initialiseDelegoPaymentWindow = async () => {

        this.setState({
            showPaymentSectionLoadingText: true,
        });

        if (this.props.paymentSettings) {

            if (this.props.paymentAccessTokenResponse &&
                (Date.now() < this.props.paymentAccessTokenResponse.expireTime - (1 * 60 * 1000))
            ) {

                this.rapidPay = this.openRapidPay(this.props.paymentAccessTokenResponse.token, (window as any).DelegoRapidPay.Show.PaymentSummary);
            }
            else {
                const tokenResponse: IPaymentTokenResponse = await this.props.dataProvider.getPaymentAccessToken?.();

                const paymentAccessToken = tokenResponse.token;

                this.rapidPay = this.openRapidPay(paymentAccessToken, (window as any).DelegoRapidPay.Show.Checkout);
                this.props.setPaymentAccessToken(tokenResponse);
            }

        }
    }

    private openRapidPay = (authorizationToken: string | undefined, show: number) => {
        const { paymentSettings } = this.props;
        const component = this;

        component.setState({
            showPaymentSectionLoadingText: false,
        });

        return (window as any).DelegoRapidPay.open({
            url: paymentSettings?.paymentURL,
            token: authorizationToken,
            show: show,
            paymentRequest: {
                /*
                 * Payment request object
                 */
                currencyCode: 'USD',
                total: 0
            },
            element: '#paymentInfoContainer',
            iframeclass: 'fitToContainer',
            appclass: 'straumann-dentogo-payment-app',
            events: {
                onApplicationInitialized: () => {
                    /*
                     * Actions to take once RapidPay is displayed
                     */

                    console.log("Application Initialized...");

                    component.setState({
                        showPaymentSectionLoadingText: false,
                    });
                },
                onTokenizeSuccess: (tokenizeResponse: any, signedTokenizeResponse: any) => {
                    /*
                     * Actions to take once RapidPay has tokenized a payment card
                     */
                    console.log("Tokenize Success...");
                    console.log("tokenizeResponse: ", tokenizeResponse);
                    console.log("signedTokenizeResponse: ", signedTokenizeResponse);

                    this.tokenizeResponse = tokenizeResponse;

                    this.props.setPaymentInfo({
                        tokenizeResponse: tokenizeResponse,
                        signedTokenizeResponse: signedTokenizeResponse
                    });

                    this.props.updateFormProperties(
                        [
                            { key: "cardToken", value: tokenizeResponse.payment.token },
                            { key: "cardType", value: tokenizeResponse.payment.cardType },
                            { key: "cardExpiryDate", value: `${tokenizeResponse.payment.cardExpiration.month}/${tokenizeResponse.payment.cardExpiration.year}` },
                            { key: "cardholderName", value: tokenizeResponse.payment.cardholderName }
                        ]
                    );

                },
                onFailure: (error: any) => {
                    /*
                     * Actions to take if RapidPay fails to tokenize a payment card (typically a system error)
                     */
                    console.log("Tokenize Failure...", error);
                    component.setState({
                        showPaymentSectionLoadingText: false,
                    });
                }
            },
            /*
             * Additional optional parameters, as desired.
             */
        });
    }

    private doPaymentRelatedSteps = async () => {
        const { paymentSettings } = this.props;
        let threeDSecureInfo: any;
        let authorization: any;

        if (paymentSettings?.paymentSteps) {

            for (let index = 0; index < paymentSettings?.paymentSteps.length; index++) {

                const step = paymentSettings?.paymentSteps[index];

                switch (step) {
                    case PaymentSteps.Tokenize:
                        // Already done nothing to do here
                        break;

                    case PaymentSteps.Authenticate:
                        threeDSecureInfo = await this.authenticatePayment();
                        break;

                    case PaymentSteps.Authorize:
                        authorization = await this.authorizePayment(threeDSecureInfo);
                        break;

                    case PaymentSteps.CreateSalesOrder:
                        await this.createSalesOrder(threeDSecureInfo);
                        break;

                    default:
                        break;
                }
            }

        }
    }

    private authenticatePayment = (): Promise<any> => {
        let authenticateRequest = {
            amount: {
                value: 0,
                currency: 'USD'
            },
            billingAddress: this.tokenizeResponse.businessAddress,
            shippingAddress: this.props.order?.businessAddress,
            payment: this.tokenizeResponse.payment, // result from the onTokenizeSuccess callback
            useVerifiableResponse: this.props.paymentSettings?.useVerifiableResponse,
        };

        return this.rapidPay.authenticate(authenticateRequest)
            .then(async (threeDSecureInfoResponse: any) => {
                const { tokenID } = this.props.paymentAccessTokenResponse as IPaymentTokenResponse;

                let threeDSecureInfo = threeDSecureInfoResponse;

                if (this.props.paymentSettings?.useVerifiableResponse) {
                    threeDSecureInfo = await this.props.dataProvider.validatePaymentToken(threeDSecureInfoResponse, tokenID)
                }
                /*
                 * threeDSecureInfo is a JWT string if useVerifiableResponse was set to true.
                 * verify the contents to receive a ThreeDSecureInfo object. Refer to
                 * "Verify Communications with RapidPay" for more details.
                 */
                // proceed based on whether threeDSecureInfo is non null and
                // the value of threeDSecureInfo.status

                if (threeDSecureInfo == null || threeDSecureInfo.status === 'Y' || threeDSecureInfo.status === 'A') {
                    // proceeding, call authorize as seen in the next section
                } else if (threeDSecureInfo.status === 'R' || threeDSecureInfo.status === 'N') {
                    // 3DS Authentication failed, ask customer for another method of payment and try again
                } else {
                    // status === U 3DS Authentication unavailable for technical or other reasons
                    // You can consult the value of statusReason for further details why the call resulted in a status U (though statusReason might not be set)
                }

                return threeDSecureInfo;
            })
            .catch((error: any) => {
                // handle any unexpected errors, probably in a similar way that you would handle a status 'U'


            });
    }

    private authorizePayment = (threeDSecureInfo: any): Promise<any> => {
        return this.rapidPay.authorize({
            amount: {
                value: 53.75,
                currency: 'USD'
            },
            preAuth: this.props.paymentSettings?.preAuth,
            payment: this.tokenizeResponse.payment,
            address: this.tokenizeResponse.billingAddress,
            useVerifiableResponse: this.props.paymentSettings?.useVerifiableResponse,
            threeDSecure: threeDSecureInfo
        }).then(async (authorizationResponse: any) => {
            /*
             * authorization is a JWT string if useVerifiableResponse was set to true.
             * verify the contents to receive an AuthorizationResponse object. Refer to
             * "Verify Communications with RapidPay" for more details.
             */
            /*
             * Create a sales order
             */
            const { tokenID } = this.props.paymentAccessTokenResponse as IPaymentTokenResponse;

            let authorization = authorizationResponse;

            if (this.props.paymentSettings?.useVerifiableResponse) {
                authorization = await this.props.dataProvider.validatePaymentToken(authorizationResponse, tokenID)
            }

            return authorization;
        });
    }

    private createSalesOrder = (authorization: any): Promise<any> => {
        return this.rapidPay.createSalesOrder({
            header: {
                docType: "TA",
                salesOrg: "3020",
                distributionChannel: "12",
                division: "00"
            },
            items: [
                {
                    itemNumber: "05",
                    material: "MSA-1000",
                    plant: "3200",
                    scheduleLines: [
                        {
                            quantity: 1
                        }
                    ],
                    partners: [
                        {
                            customerNumber: "0000003103",
                            role: "AG"
                        }
                    ]
                }
            ],
            partners: [
                {
                    customerNumber: "0000003103",
                    role: "AG",
                    address: {
                        firstName: "",
                        lastName: "",
                        street: "",
                        city: "",
                        region: "",
                        country: "",
                        postalCode: "",
                        phoneNumber: ""
                    }
                }
            ],
            authorization: authorization
        }).then((so: any) => {
            /*
             * Success! Let the shopper know that the order has been placed
             */
        }).catch((error: any) => {
            /*
             * Something went wrong while creating the order :(
             */
        });
    }

    private onChangeTextField = (fieldName: string, fieldType: CustomFieldTypes) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {

        this.setState({
            signatureName: newValue ? newValue : ''
        });

        if (newValue) {
            let newErrorState: ErrorState = {
                [fieldName]: {
                    errorMessage: '',
                    showError: false
                }
            };

            newErrorState = Common.validateFieldBasedOnType(fieldName, fieldType, true, newValue, newErrorState);

            this.setState({
                showSignatureError: newErrorState[fieldName] && newErrorState[fieldName].showError !== undefined && newErrorState[fieldName].showError !== null ? newErrorState[fieldName].showError as boolean : false
            });
        }

        this.props.updateFormData(fieldName, newValue);
    }

    private onChangeCheckBox = (key: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {

        if (key === 'acceptTermCondition') {
            this.setState({
                acceptTermCondition: checked as boolean
            });

            if (!checked) {
                this.setState({
                    showAcceptTermConditionError: true
                });
            }
            else {
                this.setState({
                    showAcceptTermConditionError: false
                });
            }
        }
        else {
            this.setState({
                acceptTermConditionAnother: checked as boolean
            });

            if (!checked) {
                this.setState({
                    showAcceptTermConditionAnotherError: true
                });

            }
            else {
                this.setState({
                    showAcceptTermConditionAnotherError: false
                });
            }
        }

        this.props.updateFormData(key, checked);
    }

    public validateSignaturesAndTerms = (): boolean => {
        let isValid = true;
        const { signatureName, acceptTermCondition, acceptTermConditionAnother } = this.state;

        if ((!this.signaturePadRef.current || this.signaturePadRef.current.toData().length === 0) && !signatureName) {
            isValid = false;
            this.setState({
                showSignatureError: true
            });
        }
        else {
            this.setState({
                showSignatureError: false
            });
        }

        if (!acceptTermCondition) {
            this.setState({
                showAcceptTermConditionError: true
            });
            isValid = false;
        } else {
            this.setState({
                showAcceptTermConditionError: false
            });
        }

        if (!acceptTermConditionAnother) {
            isValid = false;
            this.setState({
                showAcceptTermConditionAnotherError: true
            });
        }
        else {
            this.setState({
                showAcceptTermConditionAnotherError: false
            });
        }

        return isValid;
    };

    public validatePaymentDetails = (): boolean => {
        if (!this.tokenizeResponse) {
            this.setState({
                showPaymentDetailsNotFoundError: true
            });
            alert("Please enter your Credit Card information above and click “Submit” underneath your CreditCard details");
            return false;
        }
        else {
            this.setState({
                showPaymentDetailsNotFoundError: false
            });
        }

        return true;
    }

    private submitOrder = () => {
        const validateSignaturesAndTerms = this.validateSignaturesAndTerms();
        const validatePaymentDetails = this.validatePaymentDetails();

        if (validateSignaturesAndTerms && validatePaymentDetails) {

            // this.doPaymentRelatedSteps();
            this.props.onSubmitOrder?.();
        }
    }

    render() {
        const props = this.props;
        const labels = props.labels;
        const { signatureName, acceptTermCondition, acceptTermConditionAnother, showPaymentDetailsNotFoundError,
            showSignatureError, showAcceptTermConditionError, showAcceptTermConditionAnotherError, showPaymentSectionLoadingText
        } = this.state;

        let html: JSX.Element = <React.Fragment></React.Fragment>;

        if (labels)
            html = (
                <Stack verticalAlign="space-between" className="fullWidth" horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                    <Stack className={css("subscriptionContainerInner", props.stepSizeClass)} tokens={{ childrenGap: 5 }}>
                        <div>
                            <h4 className="stepHeading marginBottom10">Payment Details/Term & Conditions</h4>
                        </div>
                        <Stack className="fullWidth paymentSignatureContainer" tokens={{ childrenGap: 20 }} horizontal horizontalAlign="space-between">
                            {
                                props.isReadonly ?
                                    <Stack className="paymentInfoContainer noHeightRestriction" verticalAlign="center" tokens={{ childrenGap: 5 }}>
                                        <label><b>Cardholder Name:</b> {props.order?.cardholderName}</label>
                                        <label><b>Card Type:</b> {props.order?.cardType}</label>
                                        <label><b>Card Expiry:</b> {props.order?.cardExpiryDate}</label>
                                    </Stack> :
                                    <div className={css("paymentInfoContainer", { "payment-loader": showPaymentSectionLoadingText })} id="paymentInfoContainer">
                                        {
                                            showPaymentSectionLoadingText &&
                                            <Spinner size={SpinnerSize.large} label="Loading payment window..." ariaLive="assertive" />
                                        }
                                    </div>
                            }
                            <Stack className="signatureContainer" tokens={{ childrenGap: 5 }}>
                                {
                                    props.isReadonly ?
                                        props.order?.signatures ?
                                            <img src={props.order.signatures} alt="Signature" className="signatureImg"></img> :
                                            null :
                                        <React.Fragment>
                                            <Stack className="fullWidth " tokens={{ childrenGap: 20 }} horizontal verticalAlign="center" horizontalAlign="space-between">
                                                <div>
                                                    <h4 className="stepHeading margin0">Signature</h4>
                                                </div>
                                                <IconButton iconProps={{ iconName: 'Sync' }} title="Reset"
                                                    ariaLabel="Reset" checked={false}
                                                    onClick={() => {
                                                        this.signaturePadRef.current?.clear();
                                                        this.setState({
                                                            signatures: ''
                                                        });
                                                    }}
                                                />
                                            </Stack>
                                            <SignaturePad
                                                canvasProps={{ className: "signatureCanvas" }}
                                                ref={this.signaturePadRef}
                                                clearOnResize={false}
                                                onEnd={
                                                    (event: MouseEvent) => {
                                                        const signatures = this.signaturePadRef.current ? this.signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png') : '';
                                                        this.setState({
                                                            signatures: signatures
                                                        });

                                                        this.props.updateFormData('signatures', signatures);
                                                    }
                                                }
                                            />
                                        </React.Fragment>

                                }

                            </Stack>
                        </Stack>

                        <Stack className="fullWidth" tokens={{ childrenGap: 20 }}>
                            {
                                props.isReadonly ?
                                    props.order?.signatureName ?
                                        <div>
                                            <label className="label" >Enter your name here: </label>
                                            <label className="">{props.order.signatureName}</label>
                                        </div>
                                        : null :
                                    <TextField
                                        label="or Enter your name here"
                                        className="fullWidth signatureTextBox"
                                        value={signatureName}
                                        errorMessage={showSignatureError ? "Please provide your signature either by typing your name or adding signature" : undefined}
                                        onChange={this.onChangeTextField('signatureName', CustomFieldTypes.Text)}
                                    />
                            }
                            <Checkbox
                                disabled={props.isReadonly}
                                label={labels?.termsAndConditionLabel}
                                checked={acceptTermCondition}
                                onChange={this.onChangeCheckBox('acceptTermCondition')}
                                onRenderLabel={Common.renderCheckBoxlabel}
                            />
                            <Checkbox
                                disabled={props.isReadonly}
                                className="largeCheckBoxLabel"
                                label={
                                    props.order &&
                                        props.order?.subscriptions?.indexOf(labels.dtgSubscriptionVirtualCheck) !== -1 &&
                                        props.order?.subscriptions?.indexOf(labels.dtgSubscriptionMonitoringLight) !== -1
                                        ?
                                        labels?.termsAndConditionLabelKit : labels?.termsAndConditionLabelKit
                                }
                                checked={acceptTermConditionAnother}
                                onChange={this.onChangeCheckBox('acceptTermConditionAnother')}
                                onRenderLabel={Common.renderCheckBoxlabel}
                            />
                            {
                                showAcceptTermConditionError || showAcceptTermConditionAnotherError ?
                                    <div>
                                        <span className="errorText">Please accept all of these terms and conditions to continue.</span>
                                    </div> : null
                            }
                        </Stack>

                        {
                            showPaymentDetailsNotFoundError ?
                                <div>
                                    <span className="errorText" style={{ fontSize: 'large' }}>Please enter your Credit Card information above and click “Submit” underneath your CreditCard details</span>
                                </div> : null
                        }
                    </Stack>
                    {
                        props.isReadonly ? null :
                            props.showWizardNavigation ?
                                <StackItem className={css("wizardNavigationContainer", props.stepSizeClass)}>
                                    <WizardNavigationButtons {...props} step={props.currentStep} submitOrder={this.submitOrder}></WizardNavigationButtons>
                                </StackItem>
                                : null
                    }
                </Stack>
            );

        return html;
    }

}