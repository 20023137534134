import * as React from 'react';
import { css, Checkbox, Stack, ICheckboxStyles, StackItem, ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react';
import WizardNavigationButtons from './WizardNavigationFooter';
import { IFormProps, IMaterial } from '../Models';

export interface ISelectSubscriptionOptionProps extends IFormProps {
    materialList: IMaterial[];
}

export interface ISubscriptionOptions {
    soVirtualCheck: string | undefined;
    soMonitoringLightStarterKit?: boolean | undefined;
    soMonitoringLightTrainingOption?: string | undefined;
}

const getInitialState = (props: ISelectSubscriptionOptionProps): ISubscriptionOptions => {

    if (
        props.labels && props.order &&
        props.order.subscriptions?.indexOf(props.labels.dtgSubscriptionMonitoringLight) !== -1 &&
        props.order.soMonitoringLightStarterKit === undefined
    )
        props.updateFormData('soMonitoringLightStarterKit', false);
    else if (
        props.labels && props.order &&
        props.order.subscriptions?.indexOf(props.labels.dtgSubscriptionMonitoringLight) === -1 &&
        props.order.soMonitoringLightStarterKit) {
        props.updateFormData('soMonitoringLightStarterKit', false);
    }

    if (
        props.labels && props.order &&
        props.order.subscriptions?.indexOf(props.labels.dtgSubscriptionVirtualCheck) === -1 &&
        props.order.soVirtualCheck !== undefined &&
        props.order.soVirtualCheck !== null) {
        props.updateFormData('soVirtualCheck', null);
    }


    return {
        soVirtualCheck: props.order?.soVirtualCheck,
        soMonitoringLightStarterKit: props.order?.soMonitoringLightStarterKit === undefined ? false : props.order?.soMonitoringLightStarterKit,
        soMonitoringLightTrainingOption: props.order?.soMonitoringLightTrainingOption
    };
}

const checkboxStyles: ICheckboxStyles = {
    label: {
        fontWeight: 'bold'
    }
}



const SelectSubscriptionOption = (props: ISelectSubscriptionOptionProps) => {
    const { order, labels } = props;
    const [subscriptionOptions, setSubscriptionOptions] = React.useState(getInitialState(props));
    const [showVCError, setShowVCError] = React.useState(false);
    const [showMLError, setShowMLError] = React.useState(false);
    const [showMLKitError, setShowMLKitError] = React.useState(false);

    let html: JSX.Element = <React.Fragment></React.Fragment>;

    if (labels) {
        const trainingOptionsVC: IChoiceGroupOption[] = [
            { key: labels?.vcTrainingOption1, text: labels?.vcTrainingOption1 },
            { key: labels?.vcTrainingOption2, text: labels?.vcTrainingOption2 },
        ];

        const trainingOptionsML: IChoiceGroupOption[] = [
            { key: labels?.mlTrainingOption1, text: labels?.mlTrainingOption1 },
            { key: labels?.mlTrainingOption2, text: labels?.mlTrainingOption2 },
        ];

        const onChangeStarterKit = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
            const newSubscription: ISubscriptionOptions = {
                ...subscriptionOptions,
                soMonitoringLightStarterKit: checked
            };
            setShowMLKitError(false);
            setSubscriptionOptions(newSubscription)

            props.updateFormData('soMonitoringLightStarterKit', checked);
        }

        const onChangeTrainingOption = (name: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
            const newSubscription: ISubscriptionOptions = {
                ...subscriptionOptions,
                [name]: option?.key
            };

            if (name === 'soVirtualCheck') {
                if (newSubscription[name])
                    setShowVCError(false);
                else
                    setShowVCError(true);
            } else if (name === 'soMonitoringLightTrainingOption') {
                if (newSubscription[name])
                    setShowMLError(false);
                else
                    setShowMLError(true);
            }

            setSubscriptionOptions(newSubscription)

            props.updateFormData(name, option?.key);
        }

        const validateSubscriptionOptions = () => {
            let isValid = true;
            if (order?.subscriptions?.indexOf(labels.dtgSubscriptionVirtualCheck) !== -1) {
                if (!subscriptionOptions.soVirtualCheck) {
                    isValid = false;
                    setShowVCError(true);
                }
                else
                    setShowVCError(false);
            }

            // if (order?.subscriptions?.indexOf(labels.dtgSubscriptionMonitoringLight) !== -1) {
            //     if (!subscriptionOptions.soMonitoringLightTrainingOption) {
            //         isValid = false;
            //         setShowMLError(true);
            //     }
            //     else
            //         setShowMLError(false);
            // }

            if (!subscriptionOptions.soMonitoringLightStarterKit)
                setShowMLKitError(true);
            else
                setShowMLKitError(false);


            if (isValid) {
                if (order?.subscriptions?.indexOf(labels.dtgSubscriptionMonitoringLight) !== -1) {
                    if (props.materialList && props.materialList.length)
                        props.nextStep && props.nextStep();
                    else
                        props.goToStep && props.goToStep(4);
                }
                else
                    props.goToStep && props.goToStep(4);
            }
        };

        html = (
            <Stack verticalAlign="space-between" className="fullWidth" horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                <Stack className={css("subscriptionContainerInner", props.stepSizeClass)} tokens={{ childrenGap: 20 }}>
                    <div>
                        <h4 className="stepHeading marginBottom10">Please choose Subscription option</h4>
                    </div>
                    {
                        order?.subscriptions?.indexOf(labels.dtgSubscriptionVirtualCheck) !== -1 ?
                            <div className="subscriptionOptionContainer">
                                <legend>{labels?.dtgSubscriptionVirtualCheck}</legend>
                                <ChoiceGroup
                                    required={true}
                                    className="fieldSetContent"
                                    selectedKey={subscriptionOptions.soVirtualCheck}
                                    defaultSelectedKey={subscriptionOptions.soVirtualCheck}
                                    options={trainingOptionsVC}
                                    onChange={onChangeTrainingOption('soVirtualCheck')}
                                    label="Training Options"
                                    styles={{
                                        flexContainer: {
                                            whiteSpace: 'pre-line'
                                        }
                                    }} />
                                {
                                    showVCError ?
                                        <div>
                                            <span className="errorText">Please select atleast one Subscription Option.</span>
                                        </div> : null
                                }
                            </div>
                            : null
                    }
                    {
                        order?.subscriptions?.indexOf(labels.dtgSubscriptionMonitoringLight) !== -1 ?
                            <div className="subscriptionOptionContainer">
                                <legend>{labels?.dtgSubscriptionMonitoringLight}</legend>
                                <div className="fullWidth fieldSetContent">
                                    <div>
                                        <h4 className="stepHeading marginBottom10 required">Starter kit</h4>
                                    </div>
                                    <Checkbox
                                        styles={checkboxStyles}
                                        label={labels?.mlStarterKit}
                                        checked={subscriptionOptions.soMonitoringLightStarterKit}
                                        onChange={onChangeStarterKit}
                                    />
                                    <div className="subscriptionOptionDetails" dangerouslySetInnerHTML={{ __html: labels.mlStarterKitDescriptionLabel }} >
                                    </div>
                                    {
                                        showMLKitError ?
                                            <div>
                                                <span className="errorText">Please select Monitoring Light Starter kit.</span>
                                            </div> : null
                                    }
                                    {/* <ChoiceGroup
                                        required={true}
                                        selectedKey={subscriptionOptions.soMonitoringLightTrainingOption}
                                        defaultSelectedKey={subscriptionOptions.soMonitoringLightTrainingOption}
                                        options={trainingOptionsML}
                                        onChange={onChangeTrainingOption('soMonitoringLightTrainingOption')}
                                        label="Training Options"
                                        styles={{
                                            flexContainer: {
                                                whiteSpace: 'pre-line'
                                            }
                                        }}
                                    /> */}
                                    {
                                        showMLError ?
                                            <div>
                                                <span className="errorText">Please select atleast one Subscription Option.</span>
                                            </div> : null
                                    }
                                </div>
                            </div>
                            : null
                    }
                </Stack>
                {
                    props.showWizardNavigation ?
                        <StackItem className={css("wizardNavigationContainer", props.stepSizeClass)}>
                            <WizardNavigationButtons {...props} step={props.currentStep} nextStep={validateSubscriptionOptions}></WizardNavigationButtons>
                        </StackItem>
                        : null
                }
            </Stack >


        );
    }

    return html;
}

export default SelectSubscriptionOption;