export interface IOrder {
    id?: number;
    subscriptions?: string[];
    soVirtualCheck?: string;
    soMonitoringLightStarterKit?: boolean;
    soMonitoringLightTrainingOption?: string;
    customer?: string;
    dentalOfficeEmail?: string;
    businessName?: string;
    businessAddress?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    phoneNumber?: string;
    nameAndTitle?: string;
    clinicalEmail?: string;
    npi?: string;
    license?: string;
    taxId?: string;
    signatures?: string;
    signatureName?: string;
    acceptTermCondition?: boolean;
    acceptTermConditionAnother?: boolean;
    requestSubmitter?: IPerson;
    orderStatus?: string;
    created?: Date;
    modified?: Date;
    orderRefID?: string;
    orderNo?: string;
    cardToken?: string;
    cardType?: string;
    cardExpiryDate?: string;
    cardholderName?: string;
    country?: Country;
    gender?: string;
    speciality?: string;
    territoryManager?: string | undefined;
    territoryManagerEmail?: string | undefined;
    materialList?: string;
}

export declare type Country = 'US' | 'Canada' | undefined;

export interface IPerson {
    EMail?: string;
    Id?: number;
    Name?: string;
    Title?: string;
}

export enum CustomFieldTypes {
    Text,
    MultiLine,
    Date,
    DateTime,
    Boolean,
    Dropdown,
    Number,
    Integer,
    Floating,
    User,
    MultiUser,
    Lookup,
    MultiLookup,
    Email,
    File,
    MultiValue
}

export type ErrorState = {
    [key: string]: {
        errorMessage: string | undefined,
        showError: boolean | undefined
    };
}