import * as React from 'react';
import { TextField, Stack, StackItem, Dropdown, IDropdownOption, css } from 'office-ui-fabric-react';
import WizardNavigationButtons from './StepOrderForm/WizardNavigationFooter';
import { IFormProps, CustomFieldTypes, ErrorState, IMaterial } from './Models';
import { Common } from '../../dataprovider/Common';

export interface ICustomerInformationFormProps extends IFormProps {
    isUserAuthenticated?: boolean;
    userAccount?: any;
    states: IDropdownOption[];
    materialList?: IMaterial[];
    onSendOrderToCutomer?: () => any | void | Promise<any>;
}

export interface ICustomerInformationFormState {
    customerInformation: ICustomerInformation;
    errorState: ErrorState;
}

export interface ICustomerInformation {
    customer?: string | undefined;
    dentalOfficeEmail?: string | undefined;
    businessName?: string | undefined;
    businessAddress?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zipCode?: string | undefined;
    phoneNumber?: string | undefined;
    nameAndTitle?: string | undefined;
    clinicalEmail?: string | undefined;
    npi?: string | undefined;
    license?: string | undefined;
    taxId?: string | undefined;
    gender?: string | undefined;
    speciality?: string | undefined;
    territoryManager?: string | undefined;
    territoryManagerEmail?: string | undefined;
}


const getInitialState = (props: ICustomerInformationFormProps): ICustomerInformation => {

    return {
        customer: props.order?.customer,
        dentalOfficeEmail: props.order?.dentalOfficeEmail,
        businessName: props.order?.businessName,
        businessAddress: props.order?.businessAddress,
        city: props.order?.city,
        state: props.order?.state,
        zipCode: props.order?.zipCode,
        phoneNumber: props.order?.phoneNumber,
        nameAndTitle: props.order?.nameAndTitle,
        clinicalEmail: props.order?.clinicalEmail,
        npi: props.order?.npi,
        license: props.order?.license,
        taxId: props.order?.taxId,
        gender: props.order?.gender,
        speciality: props.order?.speciality,
        territoryManager: props.order?.territoryManager ? props.order.territoryManager : props.isUserAuthenticated && props.userAccount ? props.userAccount.displayName : null,
        territoryManagerEmail: props.order?.territoryManagerEmail ? props.order.territoryManagerEmail : props.isUserAuthenticated && props.userAccount ? props.userAccount.mail : null,
    };
}

const getInitialErrorState = (props: ICustomerInformationFormProps): ErrorState => {

    return {
        customer: {
            errorMessage: '',
            showError: false
        },
        dentalOfficeEmail: {
            errorMessage: '',
            showError: false
        },
        businessName: {
            errorMessage: '',
            showError: false
        },
        businessAddress: {
            errorMessage: '',
            showError: false
        },
        city: {
            errorMessage: '',
            showError: false
        },
        state: {
            errorMessage: '',
            showError: false
        },
        zipCode: {
            errorMessage: '',
            showError: false
        },
        phoneNumber: {
            errorMessage: '',
            showError: false
        },
        nameAndTitle: {
            errorMessage: '',
            showError: false
        },
        clinicalEmail: {
            errorMessage: '',
            showError: false
        },
        npi: {
            errorMessage: '',
            showError: false
        },
        license: {
            errorMessage: '',
            showError: false
        },
        taxId: {
            errorMessage: '',
            showError: false
        },
        gender: {
            errorMessage: '',
            showError: false
        },
        speciality: {
            errorMessage: '',
            showError: false
        },
        territoryManager: {
            errorMessage: '',
            showError: false
        },
        territoryManagerEmail: {
            errorMessage: '',
            showError: false
        }
    };
}


export default class CustomerInformationForm extends React.Component<ICustomerInformationFormProps, ICustomerInformationFormState> {


    constructor(props: ICustomerInformationFormProps) {
        super(props);

        this.state = {
            customerInformation: getInitialState(props),
            errorState: getInitialErrorState(props)
        }
    }

    componentDidMount() {
        const customerInformation = this.state.customerInformation;

        if (customerInformation.territoryManager || customerInformation.territoryManagerEmail)
            this.props.updateFormProperties([
                { key: 'territoryManager', value: customerInformation?.territoryManager ?? null },
                { key: 'territoryManagerEmail', value: customerInformation?.territoryManagerEmail ?? null }
            ]);
    }

    componentDidUpdate(prevProps: ICustomerInformationFormProps, prevState: ICustomerInformationFormState) {
        if (JSON.stringify(prevProps.order) !== JSON.stringify(this.props.order)) {
            this.setState({
                customerInformation: getInitialState(this.props)
            });
        }
    }

    private onChangeTextField = (fieldName: string, fieldType: CustomFieldTypes) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        const newCustomerInformation = {
            ...this.state.customerInformation,
            [fieldName]: newValue
        };

        this.setState({
            customerInformation: newCustomerInformation
        });

        if (newValue) {
            let newErrorState = { ...this.state.errorState };

            newErrorState = Common.validateFieldBasedOnType(fieldName, fieldType, true, newValue, newErrorState);

            this.setState({
                errorState: newErrorState
            });
        }

        this.props.updateFormData(fieldName, newValue);
    }

    private onChangeDropdownField = (fieldName: string, fieldType: CustomFieldTypes) => (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
        const newCustomerInformation = {
            ...this.state.customerInformation,
            [fieldName]: option?.key
        };

        this.setState({
            customerInformation: newCustomerInformation
        });

        if (option?.key) {
            let newErrorState = { ...this.state.errorState };

            newErrorState = Common.validateFieldBasedOnType(fieldName, fieldType, true, option?.key, newErrorState);

            this.setState({
                errorState: newErrorState
            });
        }

        this.props.updateFormData(fieldName, option?.key);
    };

    public validateCustomerInfo = (): boolean => {
        const customerInformation = this.state.customerInformation;
        let isValid = true;
        let newErrorState = { ...this.state.errorState };

        Object.entries(customerInformation).forEach(entry => {

            if (this.props.order?.country === 'Canada' && (entry[0] === 'npi' || entry[0] === 'taxId')) {
                //Do nothing and skip validation
            } else {

                let fieldType: CustomFieldTypes;

                switch (entry[0]) {
                    case 'dentalOfficeEmail':
                    case 'clinicalEmail':
                        fieldType = CustomFieldTypes.Email;
                        break;
                    case 'businessAddress':
                        fieldType = CustomFieldTypes.MultiLine;
                        break;

                    default:
                        fieldType = CustomFieldTypes.Text;
                }

                newErrorState = Common.validateFieldBasedOnType(entry[0], fieldType, true, entry[1], newErrorState);
                if (newErrorState[entry[0]].showError)
                    isValid = false;
            }

        });

        if (!isValid) {
            this.setState({
                errorState: newErrorState
            });
        }

        return isValid;
    };

    private goToNextStep = () => {
        if (this.validateCustomerInfo()) {
            this.props.nextStep && this.props.nextStep();
        }
    };

    private goToPreviousStep = () => {
        const props = this.props;

        if (this.props.labels && this.props.order?.subscriptions?.indexOf(this.props.labels.dtgSubscriptionMonitoringLight) !== -1) {
            if (props.materialList && props.materialList.length)
                props.previousStep && props.previousStep();
            else
                props.goToStep && props.goToStep(2);
        }
        else
            props.goToStep && props.goToStep(2);
    }

    private sendToCustomer = () => {
        if (this.validateCustomerInfo()) {
            this.props.onSendOrderToCutomer?.();
        }
    };

    render() {
        const props = this.props;
        const { errorState, customerInformation } = this.state;
        return (
            <Stack verticalAlign="space-between" className="fullWidth" horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                <Stack className={css("subscriptionContainerInner", props.stepSizeClass)} tokens={{ childrenGap: 5 }}>
                    <div>
                        <h4 className="stepHeading">Customer Information</h4>
                    </div>
                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", "zeroPadding")}>
                            <label className="required label" >Country</label>
                        </StackItem>
                        <StackItem className="col">
                            <label className="">{props.order?.country}</label>
                        </StackItem>
                    </Stack>
                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Customer (Business Name)</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.customer}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.customer}
                                        errorMessage={errorState.customer.showError ? errorState.customer.errorMessage : undefined}
                                        onChange={this.onChangeTextField('customer', CustomFieldTypes.Text)}
                                    />
                            }
                        </StackItem>

                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Point of Contact E-mail</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.dentalOfficeEmail}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.dentalOfficeEmail}
                                        errorMessage={errorState.dentalOfficeEmail.showError ? errorState.dentalOfficeEmail.errorMessage : undefined}
                                        onChange={this.onChangeTextField('dentalOfficeEmail', CustomFieldTypes.Email)}
                                    />
                            }
                        </StackItem>
                    </Stack>

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Business Name</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.businessName}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.businessName}
                                        errorMessage={errorState.businessName.showError ? errorState.businessName.errorMessage : undefined}
                                        onChange={this.onChangeTextField('businessName', CustomFieldTypes.Text)}
                                    />
                            }
                        </StackItem>
                    </Stack>

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Business Address</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="richTextLabel" dangerouslySetInnerHTML={{ __html: customerInformation.businessAddress || "" }} ></label> :
                                    <TextField
                                        className="fullWidth"
                                        multiline
                                        rows={4}
                                        value={customerInformation.businessAddress}
                                        errorMessage={errorState.businessAddress.showError ? errorState.businessAddress.errorMessage : undefined}
                                        onChange={this.onChangeTextField('businessAddress', CustomFieldTypes.MultiLine)}
                                    />
                            }
                        </StackItem>
                    </Stack>

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >City</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.city}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.city}
                                        errorMessage={errorState.city.showError ? errorState.city.errorMessage : undefined}
                                        onChange={this.onChangeTextField('city', CustomFieldTypes.Text)}
                                    />
                            }
                        </StackItem>

                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >{props.order?.country === 'US' ? 'State' : 'Province'}</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.state}</label> :
                                    <Dropdown
                                        className="fullWidth"
                                        options={props.states ? props.states : []}
                                        selectedKey={customerInformation.state}
                                        placeholder={props.order?.country === 'US' ? 'Select State' : 'Select Province'}
                                        ariaLabel={props.order?.country === 'US' ? 'Select State' : 'Select Province'}
                                        errorMessage={errorState.state.showError ? errorState.state.errorMessage : undefined}
                                        onChange={this.onChangeDropdownField('state', CustomFieldTypes.Text)}
                                    />
                            }
                        </StackItem>
                    </Stack>

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Zip Code</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.zipCode}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.zipCode}
                                        errorMessage={errorState.zipCode.showError ? errorState.zipCode.errorMessage : undefined}
                                        onChange={this.onChangeTextField('zipCode', CustomFieldTypes.Text)}
                                    />
                            }
                        </StackItem>
                    </Stack>

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Phone Number</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.phoneNumber}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.phoneNumber}
                                        errorMessage={errorState.phoneNumber.showError ? errorState.phoneNumber.errorMessage : undefined}
                                        onChange={this.onChangeTextField('phoneNumber', CustomFieldTypes.Text)}
                                    />
                            }
                        </StackItem>
                    </Stack>

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Name and Title</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.nameAndTitle}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.nameAndTitle}
                                        errorMessage={errorState.nameAndTitle.showError ? errorState.nameAndTitle.errorMessage : undefined}
                                        onChange={this.onChangeTextField('nameAndTitle', CustomFieldTypes.Text)}
                                    />
                            }
                        </StackItem>

                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Gender</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.gender}</label> :
                                    <Dropdown
                                        className="fullWidth"
                                        options={[{ key: "Female", text: "Female" }, { key: "Male", text: "Male" }, { key: "Do not wish to answer", text: "Do not wish to answer" }]}
                                        selectedKey={customerInformation.gender}
                                        placeholder={"Select Gender"}
                                        ariaLabel={"Select Gender"}
                                        errorMessage={errorState.gender.showError ? errorState.gender.errorMessage : undefined}
                                        onChange={this.onChangeDropdownField('gender', CustomFieldTypes.Text)}
                                    />
                            }
                        </StackItem>

                    </Stack>

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Clinical Email</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.clinicalEmail}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.clinicalEmail}
                                        errorMessage={errorState.clinicalEmail.showError ? errorState.clinicalEmail.errorMessage : undefined}
                                        onChange={this.onChangeTextField('clinicalEmail', CustomFieldTypes.Email)}
                                    />

                            }
                        </StackItem>

                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Speciality</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.speciality}</label> :
                                    <Dropdown
                                        className="fullWidth"
                                        options={[{ key: "Orthodontist", text: "Orthodontist" }, { key: "General Practitioner", text: "General Practitioner" }, { key: "Other", text: "Other" }]}
                                        selectedKey={customerInformation.speciality}
                                        placeholder={"Select Speciality"}
                                        ariaLabel={"Select Speciality"}
                                        errorMessage={errorState.speciality.showError ? errorState.speciality.errorMessage : undefined}
                                        onChange={this.onChangeDropdownField('speciality', CustomFieldTypes.Text)}
                                    />

                            }
                        </StackItem>
                    </Stack>

                    {
                        props.order?.country === 'US' &&
                        <Stack horizontal className="formRow4Col">
                            <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                                <label className="required label" >NPI #</label>
                            </StackItem>
                            <StackItem className="col">
                                {
                                    props.isReadonly ?
                                        <label className="">{customerInformation.npi}</label> :
                                        <TextField
                                            className="fullWidth"
                                            value={customerInformation.npi}
                                            errorMessage={errorState.npi.showError ? errorState.npi.errorMessage : undefined}
                                            onChange={this.onChangeTextField('npi', CustomFieldTypes.Text)}
                                        />

                                }
                            </StackItem>
                        </Stack>
                    }

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >{props.order?.country === 'US' ? "License #" : "License number/Registration"}</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.license}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.license}
                                        errorMessage={errorState.license.showError ? errorState.license.errorMessage : undefined}
                                        onChange={this.onChangeTextField('license', CustomFieldTypes.Text)}
                                    />

                            }
                        </StackItem>
                    </Stack>

                    {
                        props.order?.country === 'US' &&
                        <Stack horizontal className="formRow4Col">
                            <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                                <label className="required label" >Tax ID #</label>
                            </StackItem>
                            <StackItem className="col">
                                {
                                    props.isReadonly ?
                                        <label className="">{customerInformation.taxId}</label> :
                                        <TextField
                                            className="fullWidth"
                                            value={customerInformation.taxId}
                                            errorMessage={errorState.taxId.showError ? errorState.taxId.errorMessage : undefined}
                                            onChange={this.onChangeTextField('taxId', CustomFieldTypes.Text)}
                                        />

                                }
                            </StackItem>
                        </Stack>
                    }

                    <Stack horizontal className="formRow4Col">
                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Territory Manager</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.territoryManager}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.territoryManager}
                                        errorMessage={errorState.territoryManager.showError ? errorState.territoryManager.errorMessage : undefined}
                                        onChange={this.onChangeTextField('territoryManager', CustomFieldTypes.Text)}
                                    />

                            }
                        </StackItem>

                        <StackItem className={css("col", { zeroPadding: props.isReadonly })}>
                            <label className="required label" >Territory Manager E-Mail</label>
                        </StackItem>
                        <StackItem className="col">
                            {
                                props.isReadonly ?
                                    <label className="">{customerInformation.territoryManagerEmail}</label> :
                                    <TextField
                                        className="fullWidth"
                                        value={customerInformation.territoryManagerEmail}
                                        errorMessage={errorState.territoryManagerEmail.showError ? errorState.territoryManagerEmail.errorMessage : undefined}
                                        onChange={this.onChangeTextField('territoryManagerEmail', CustomFieldTypes.Email)}
                                    />

                            }
                        </StackItem>
                    </Stack>

                </Stack>
                {
                    props.showWizardNavigation ?
                        <StackItem className={css("wizardNavigationContainer", props.stepSizeClass)}>
                            <WizardNavigationButtons {...props} step={props.currentStep} sendToCustomer={this.sendToCustomer} previousStep={this.goToPreviousStep} nextStep={this.goToNextStep}></WizardNavigationButtons>
                        </StackItem>
                        : null
                }


            </Stack>
        );
    }


}