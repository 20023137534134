import * as React from 'react';
import '../OrderForm.scss';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { IDropdownOption, DialogType, Spinner, SpinnerSize, Dialog, MessageBar, MessageBarType, Stack, css, Text } from 'office-ui-fabric-react';
import SelectSubscription from './SelectSubscription';
import { IDataProvider } from '../../../dataprovider/IDataProvider';
import SelectSubscriptionOption from './SelectSubscriptionOption';
import CustomerInformationForm from '../CustomerInformationForm';
import CustomerAgreementForm from '../CustomerAgreementForm';
import StepWizardWrapper from './StepWizardWrapper';
import SuccessBox from './SuccessBox';
import { AnimationStyles, AnimationClassNames, AnimationVariables } from 'office-ui-fabric-react/lib/Styling';
import { IOrder, IApplicationLabels, IPaymentSettings, IApplicationSettings, IPaymentTokenResponse, Country, IMaterial } from '../Models';
import { Common } from '../../../dataprovider/Common';
import { Link } from 'react-router-dom';
import MaterialSelectionForm from '../MaterialSelectionForm';

export interface IOrderFormProps {
    isUserAuthenticated: boolean;
    userAccount: any;
    order?: IOrder;
    dataProvider: IDataProvider;
    location: Location;
    history: History;
}

export interface IOrderFormState {
    showWaitingSpinner?: boolean;
    order?: IOrder;
    labels: IApplicationLabels | null;
    stepWizardContext?: StepWizardProps | null;
    states?: IDropdownOption[];
    message?: string | JSX.Element | null;
    messageType?: MessageBarType;
    showOrderSuccessMessage?: boolean;
    currentStep?: number;
    paymentSettings?: IPaymentSettings;
    paymentInfo?: any;
    paymentAccessTokenResponse?: IPaymentTokenResponse;
    isLoading?: boolean;
    materialList: IMaterial[];
}

const transitions = {
    enterRight: `animated enterRight`,
    enterLeft: `animated enterLeft`,
    exitRight: `.animated exitRight`,
    exitLeft: `animated exitLeft`,
    intro: `animated intro`,
};

// const transitions = {
//     enterRight: `animated ${AnimationClassNames.slideRightIn400}`,
//     enterLeft: `animated ${AnimationClassNames.slideLeftIn400}`,
//     exitRight: `.animated ${AnimationClassNames.slideRightOut400}`,
//     exitLeft: `animated ${AnimationClassNames.slideLeftOut400}`,
//     intro: `animated intro`,
// };

export class OrderForm extends React.Component<IOrderFormProps, IOrderFormState> {
    static displayName = OrderForm.name;
    private country: Country;

    constructor(props: any) {
        super(props);
        this.state = {
            order: {},
            showWaitingSpinner: false,
            labels: null,
            stepWizardContext: null,
            states: [],
            message: null,
            messageType: MessageBarType.success,
            showOrderSuccessMessage: false,
            currentStep: 0,
            isLoading: true,
            materialList: []
        }
    }

    async componentDidMount() {
        try {
            const order: IOrder = { ...this.state.order };
            this.country = sessionStorage.getItem('Country') as Country;

            order.country = this.country;

            if (this.country) {
                const applicationSettings: IApplicationSettings = await this.props.dataProvider.getApplicationSettings(this.country);

                if (applicationSettings) {
                    Common.loadScript(`${applicationSettings.paymentSettings.paymentURL}/sdk/sdk.bundle.js`, () => {
                        this.setState({
                            labels: applicationSettings.applicationLabels,
                            paymentSettings: applicationSettings.paymentSettings,
                            materialList: applicationSettings.materialList
                        });
                    });
                }

                const states = this.props.dataProvider.getStatesLists(this.country);
                states.sort();

                this.setState({
                    order: order,
                    states: states.map(state => ({ key: state, text: state })),
                    isLoading: false
                });
            }

        } catch (error) {
            this.hideSpinnerAndShowErrorMessage(error, "_onClickSavePricingAppAdmin");
        }

    }

    private updateForm = (key: string, value: any) => {
        const order = { ...this.state.order };

        (order as any)[key] = value;
        this.setState({
            order,
        });
    };

    private updateFormProperties = (properties: { key: string, value: any }[]) => {
        const order = { ...this.state.order };

        for (const property of properties) {
            (order as any)[property.key] = property.value;
        }

        this.setState({
            order,
        });
    };

    // Do something on step change
    private onStepChange = (stats: any) => {
        // console.log(stats);
        this.setState({
            currentStep: stats.activeStep
        })
    };

    private setInstance = (stepWizardContext: StepWizardProps) => this.setState({
        stepWizardContext,
    });


    private setPaymentAccessToken = (accessTokenResponse: IPaymentTokenResponse) => {
        this.setState({
            paymentAccessTokenResponse: accessTokenResponse
        });
    }

    private setPaymentInfo = (paymentInfo: any) => {
        this.setState({
            paymentInfo: paymentInfo
        });
    }

    private onSendOrderToCutomer = () => {
        this.setState({
            showWaitingSpinner: true
        })

        if (this.state.order) {
            const order = { ...this.state.order };

            if (this.props.isUserAuthenticated)
                order.requestSubmitter = {
                    EMail: this.props.userAccount.mail,
                    Title: this.props.userAccount.displayName,
                };

            order.orderStatus = "Sent To Customer";
           
            let materialList: IMaterial[] = order.materialList ? JSON.parse(order.materialList) : [];
            materialList = materialList.filter(x=>x.quantity && x.quantity > 0);
           
            order.materialList = JSON.stringify(materialList);

            this.props.dataProvider.createSendToCutomerOrder(order).then((result: any) => {
                this.hideSpinnerAndShowSuccessMessage("Order sent to customer successfully.");
            }).catch(error => {
                this.hideSpinnerAndShowErrorMessage(error, "_onClickSavePricingAppAdmin");
            });
        }

    }

    private onCreateOrder = () => {
        this.setState({
            showWaitingSpinner: true
        })

        if (this.state.order) {
            const order = { ...this.state.order };

            if (this.props.isUserAuthenticated)
                order.requestSubmitter = {
                    EMail: this.props.userAccount.mail,
                    Title: this.props.userAccount.displayName,
                };

            order.orderStatus = "Completed";

            let materialList: IMaterial[] = order.materialList ? JSON.parse(order.materialList) : [];
            materialList = materialList.filter(x=>x.quantity && x.quantity > 0);
           
            order.materialList = JSON.stringify(materialList);
            
            this.props.dataProvider.createOrder(order).then((result: any) => {
                this.hideSpinnerAndShowSuccessMessage("Order created successfully. Please check your mail for confirmation.");
            }).catch(error => {
                this.hideSpinnerAndShowErrorMessage(error, "_onClickSavePricingAppAdmin");
            });
        }

    }


    private hideSpinnerAndShowSuccessMessage = (message: string) => {
        this.setState({
            message: message,
            messageType: MessageBarType.success,
            showWaitingSpinner: false,
            showOrderSuccessMessage: true,
        });
    }

    private hideSpinnerAndShowErrorMessage = (error: any, errorAreaName: string) => {

        this.props.dataProvider.logErrorInConsole?.(errorAreaName, error);

        this.setState({
            message: this.props.dataProvider.extractErrorMessageFromErrorObject?.(error),
            messageType: MessageBarType.error,
            showWaitingSpinner: false,
            showOrderSuccessMessage: false,
        });
    }

    render() {
        const {
            showOrderSuccessMessage, message, stepWizardContext, currentStep, isLoading, messageType,
            order, materialList, labels, showWaitingSpinner, paymentAccessTokenResponse, states,
            paymentInfo, paymentSettings
        } = this.state;

        const { location, history, isUserAuthenticated, userAccount } = this.props;

        // console.log(stepWizardContext);

        if (isLoading) {
            return (
                <Stack className="loggingInContainer" horizontalAlign="center" verticalAlign="center" reversed={true} >
                    <span>Loading</span>
                    <div className="loggingInLoader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </Stack>
            );
        }
        else {
            if (this.country) {
                if (this.state.labels)
                    return (
                        <React.Fragment>
                            <Stack className="orderForm fullWidth" tokens={{ childrenGap: 15 }} verticalAlign="space-between">
                                {
                                    !showOrderSuccessMessage ?
                                        <React.Fragment>
                                            {
                                                this.state.message ?
                                                    <MessageBar
                                                        onDismiss={() => {
                                                            this.setState({
                                                                message: null
                                                            });
                                                        }}
                                                        messageBarType={messageType}
                                                        isMultiline={true}
                                                        truncated={true}
                                                    >
                                                        {message}
                                                    </MessageBar>
                                                    : null
                                            }
                                            {
                                                stepWizardContext ?
                                                    <Stack horizontal horizontalAlign="center" className="fullWidth">
                                                        <Stack horizontal horizontalAlign="start" className={css({
                                                            subscriptionContainerInnerWidth50: !currentStep || currentStep < 3,
                                                            subscriptionContainerInnerWidth90: currentStep !== undefined && currentStep > 2
                                                        })}>
                                                            <h2 className="pageHeading">Service Agreement</h2>
                                                        </Stack>
                                                    </Stack>
                                                    : null
                                            }
                                            <StepWizard
                                                className="fullWidth"
                                                onStepChange={this.onStepChange}
                                                isHashEnabled={false}
                                                isLazyMount={true}
                                                transitions={transitions}
                                                instance={this.setInstance}
                                            >
                                                <SelectSubscription
                                                    isReadonly={false}
                                                    hashKey={'SelectSubscription'}
                                                    updateFormData={this.updateForm}
                                                    updateFormProperties={this.updateFormProperties}
                                                    labels={labels}
                                                    order={order}
                                                    currentStep={1}
                                                    showWizardNavigation={true}
                                                    stepSizeClass="subscriptionContainerInnerWidth50"
                                                    isUserAuthenticated={isUserAuthenticated}
                                                />
                                                <SelectSubscriptionOption
                                                    isReadonly={false}
                                                    hashKey={'SelectSubscriptionOption'}
                                                    updateFormData={this.updateForm}
                                                    updateFormProperties={this.updateFormProperties}
                                                    labels={labels}
                                                    order={order}
                                                    materialList={materialList}
                                                    currentStep={2}
                                                    showWizardNavigation={true}
                                                    stepSizeClass="subscriptionContainerInnerWidth50"
                                                    isUserAuthenticated={isUserAuthenticated}
                                                />
                                                <MaterialSelectionForm
                                                    isReadonly={false}
                                                    hashKey={'SelectMaterial'}
                                                    updateFormData={this.updateForm}
                                                    updateFormProperties={this.updateFormProperties}
                                                    labels={labels}
                                                    order={order}
                                                    materialList={materialList}
                                                    currentStep={3}
                                                    showWizardNavigation={true}
                                                    stepSizeClass="subscriptionContainerInnerWidth90"
                                                    isUserAuthenticated={isUserAuthenticated}
                                                />
                                                <CustomerInformationForm
                                                    isReadonly={false}
                                                    hashKey={'CustomerInformationForm'}
                                                    updateFormData={this.updateForm}
                                                    updateFormProperties={this.updateFormProperties}
                                                    labels={labels}
                                                    order={order}
                                                    materialList={materialList}
                                                    states={states ? states : []}
                                                    currentStep={4}
                                                    onSendOrderToCutomer={this.onSendOrderToCutomer}
                                                    showWizardNavigation={true}
                                                    stepSizeClass="subscriptionContainerInnerWidth90"
                                                    isUserAuthenticated={isUserAuthenticated}
                                                    userAccount={userAccount}
                                                />
                                                <CustomerAgreementForm
                                                    isReadonly={false}
                                                    hashKey={'CustomerAgreementForm'}
                                                    updateFormData={this.updateForm}
                                                    updateFormProperties={this.updateFormProperties}
                                                    labels={labels}
                                                    order={order}
                                                    onSubmitOrder={this.onCreateOrder}
                                                    currentStep={5}
                                                    showWizardNavigation={true}
                                                    stepSizeClass="subscriptionContainerInnerWidth90"
                                                    isUserAuthenticated={isUserAuthenticated}
                                                    dataProvider={this.props.dataProvider}
                                                    paymentSettings={paymentSettings}
                                                    setPaymentAccessToken={this.setPaymentAccessToken}
                                                    setPaymentInfo={this.setPaymentInfo}
                                                    paymentAccessTokenResponse={paymentAccessTokenResponse}
                                                    paymentInfo={paymentInfo}
                                                />
                                            </StepWizard>
                                            <Dialog
                                                hidden={!showWaitingSpinner}
                                                dialogContentProps={{
                                                    type: DialogType.normal,
                                                }}
                                                modalProps={{ isBlocking: true, className: "busyOverlay" }}
                                            >
                                                <Spinner size={SpinnerSize.large} label="Working on it..." ariaLive="assertive" />
                                            </Dialog>
                                        </React.Fragment>
                                        :
                                        <SuccessBox location={location} history={history} message={String(message)} stepSizeClass="subscriptionContainerInnerWidth50"></SuccessBox>
                                }

                            </Stack>

                        </React.Fragment>
                    );
                else
                    return null;

            }
            else {
                return <Text variant="medium">Country not specified. Click <Link to={"/"}>here</Link> to start again</Text>;
            }
        }

    }
}

