import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout/Layout';
import { OrderForm } from './components/OrderForm/StepOrderForm/OrderForm';
import { OrderSummaryForm } from './components/OrderForm/CompleteForm/OrderSummaryForm';
import AuthProvider from './components/AuthenticationProvider/AuthenticationProvider';
import WelcomePage from './components/WelcomePage/Welcome';

import './Common.scss'
// import MockService from './dataprovider/MockService';
import DataProvider from './dataprovider/DataProvider';

export const PageURL = {
  CreateOrder: "/pages/CustomerCreateOrder",
  CreateOrderAuthenticated: "/pages/CreateOrder",
  OrderSummary: "/pages/OrderSummary"
}

export default class App extends Component {
  static displayName = App.name;

  // dataProvider = new MockService();
  dataProvider = new DataProvider();

  render() {

    const AuthenticatedOrderForm = AuthProvider(OrderForm);

    return (
      <Layout>
        {/* <Route exact path='/' >
          <Redirect to={PageURL.CreateOrderAuthenticated} />
        </Route> */}
        <Route exact path='/' component={WelcomePage} ></Route>
        <Route exact
          path={PageURL.CreateOrderAuthenticated}
          render={(props) => (
            <AuthenticatedOrderForm
              {...props}
              dataProvider={this.dataProvider}
            />
          )}
        />
        <Route exact
          path={PageURL.CreateOrder}
          render={(props) => (
            <OrderForm {...props} dataProvider={this.dataProvider} />
          )}
        />
        <Route exact
          path={PageURL.OrderSummary}
          render={(props) => (
            <OrderSummaryForm {...props} dataProvider={this.dataProvider} />
          )}
        />
      </Layout>
    );
  }
}
