import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme } from 'office-ui-fabric-react';
import { PageURL } from '../../App';
import './Welcome.scss';
import flagUS from '../../images/USFlag.svg';
import flagCanada from '../../images/CanadaFlag.svg';

const theme = getTheme();

export interface IWelcomePageProps {
    location: Location;
    history: any;
    stepSizeClass: string;
}

const WelcomePage = (props: IWelcomePageProps) => {
    const [country, setCountry] = React.useState("");

    return (
        <Stack horizontal verticalAlign="center" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
            <Stack verticalAlign="space-between" horizontalAlign="center" className={css("subscriptionContainerInner", props.stepSizeClass, "animate", "popIn")} tokens={{ childrenGap: 5 }}>
                <h1 className="welcomeHeading">Welcome to Dental Monitoring™</h1>
                {
                    country ?
                        <Stack verticalAlign="space-between" horizontalAlign="center" className="animate popIn">
                            <p className="heading">Want to create Order?</p>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                                <PrimaryButton
                                    text="Create Order as Employee"
                                    className="linkButton"
                                    onClick={() => {
                                        sessionStorage.setItem("Country", country);
                                        props.history.push(PageURL.CreateOrderAuthenticated);
                                    }}
                                    allowDisabledFocus
                                />
                                <PrimaryButton
                                    text="Create Order as Customer"
                                    className="linkButton"
                                    onClick={() => {
                                        sessionStorage.setItem("Country", country);
                                        props.history.push(PageURL.CreateOrder);
                                    }}
                                    allowDisabledFocus
                                />
                            </Stack>
                        </Stack>
                        :
                        <Stack verticalAlign="space-between" horizontalAlign="center" className="">
                            <p className="heading">Choose Country</p>
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }}>
                                <Stack verticalAlign="space-between" horizontalAlign="center" tokens={{ childrenGap: 20 }} className="actionButtonContainer">
                                    <button className="flagButton" onClick={() => setCountry('US')}><img className="flag" src={flagUS} alt="US"></img></button>
                                    {/* <Text variant="large" >US</Text> */}
                                </Stack>

                                <Stack verticalAlign="space-between" horizontalAlign="center" tokens={{ childrenGap: 20 }} className="actionButtonContainer">
                                    <button className="flagButton" onClick={() => setCountry('Canada')}><img className="flag" src={flagCanada} alt="Canada"></img></button>
                                    {/* <Text variant="large" >Canada</Text> */}
                                </Stack>
                            </Stack>

                        </Stack>
                }


            </Stack>

        </Stack>
    );
}

export default WelcomePage;