import { IDataProvider } from "./IDataProvider";
import { IApplicationLabels } from "../components/OrderForm/Models/IApplicationLabels";
import HttpService from "./HttpService";
import { IPaymentSettings, IOrder, IApplicationSettings, IPaymentTokenResponse, Country } from "../components/OrderForm/Models";

export default class DataProvider implements IDataProvider {

    public apiEndpointSP = `/api/SPInterface`;
    public apiEndpointPayment = `/api/Payment`;
    public apiEndpointAppSettings = `/api/ApplicationSettings`;

    public async getApplicationSettings(country: string | null): Promise<IApplicationSettings> {
        const applicationSettings: IApplicationSettings = await HttpService.get(`${this.apiEndpointAppSettings}/${country}`);
        return applicationSettings;
    }

    public async getApplicationLables(country: string | null): Promise<IApplicationLabels> {
        const applicationLabels: IApplicationLabels = await HttpService.get(`${this.apiEndpointSP}/GetApplicationLabels/${country}`);
        return applicationLabels;
    }

    public async getPaymentSettings(): Promise<IPaymentSettings> {
        const paymentSettings: IPaymentSettings = await HttpService.get(`${this.apiEndpointPayment}/GetPaymentSettings`);
        return paymentSettings;
    }

    public async getPaymentAccessToken(): Promise<IPaymentTokenResponse> {
        const paymentAccessTokenResponse: IPaymentTokenResponse = await HttpService.get(`${this.apiEndpointPayment}/GetAccessToken`);
        return paymentAccessTokenResponse;
    }

    public async validatePaymentToken(token: string, tokenID: string): Promise<any> {
        const decodedToken: any = await HttpService.get(`${this.apiEndpointPayment}/ValidateToken/${token}/${tokenID}`);
        return decodedToken;
    }

    public async getOrder(orderId: string): Promise<IOrder> {
        //const order: IOrder = await HttpService.get(`${this.apiEndpointSP}/Order/${orderId}`);
        const order: IOrder = await HttpService.get(`${this.apiEndpointSP}/Order/${encodeURIComponent(orderId)}`);

        order.created = order.created ? new Date(order.created.toString()) : undefined;
        order.modified = order.modified ? new Date(order.modified.toString()) : undefined;

        return order;
    }

    public async submitSignedOrder(order: IOrder): Promise<any> {

        const response = await HttpService.post(`${this.apiEndpointSP}/SubmitOrder`, order);
        return response
    }

    public async createSendToCutomerOrder(order: IOrder): Promise<any> {
        const response = await HttpService.post(`${this.apiEndpointSP}/CreateSendToCustomerOrder`, order);
        return response
    }

    public async createOrder(order: IOrder): Promise<any> {

        const response = await HttpService.post(`${this.apiEndpointSP}/CreateOrder`, order);
        return response
    }

    public logErrorInConsole(errorArea: string, errorObj: any) {
        this.logError(errorObj.message);
        console.log(`Error message ${errorArea}: `, errorObj.message);
        console.log(`Error Stack Trace ${errorArea}: `, errorObj.stack);
    }

    private logError(msg: any) {
        if (console) {
            console.log(`%cERROR:`, "color:red;font-weight:bold;font-size:16px");
            console.log(msg);
        }
    }

    public extractErrorMessageFromErrorObject(error: any): string {

        let errorMessage = error.message ? error.message as string : error as string;

        if (errorMessage.indexOf("::>") >= 0) {
            try {
                let errorObj = JSON.parse(errorMessage.substring(errorMessage.indexOf("::>") + 3).trim());

                if (errorObj["odata.error"] && errorObj["odata.error"].message && errorObj["odata.error"].message.value)
                    errorMessage = errorObj["odata.error"].message.value;

            } catch (error) {
                errorMessage = errorMessage.substring(errorMessage.indexOf("::>") + 3).trim();
            }

        }

        return errorMessage;
    }

    public getStatesLists(country: Country) {
        if (country === 'US')
            return this.getStatesListsUS();
        else if (country === 'Canada')
            return this.getProvinceListCA();
        else
            return [];
    }

    public getStatesListsUS(): string[] {
        return [
            'Alabama',
            'Alaska',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'Florida',
            'Georgia',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virginia',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming'
        ]
    }

    public getProvinceListCA(): string[] {
        return [
            'Alberta',
            'British Columbia',
            'Manitoba',
            'New Brunswick',
            'Newfoundland and Labrador',
            'Northwest Territories',
            'Nova Scotia',
            'Nunavut',
            'Ontario',
            'Prince Edward Island',
            'Quebec',
            'Saskatchewan',
            'Yukon'
        ];
    }

}