export const msalConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/d2c36cc7-0f21-42d6-811b-0c732a0ac4a6',
    clientId: '2ab2d63e-80f9-4ba8-bd9c-d41a256201df',
    // redirectUri: window.location.origin + window.location.pathname,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"]
};

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
export const tokenRequest = {
  scopes: ["User.Read", "Mail.Read"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages"
};

// export const msalInstance = new msal.PublicClientApplication(msalConfig);

export const fetchMsGraph = async (url: string, accessToken: string) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  return response.json();
};