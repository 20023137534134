import * as React from 'react';
import { CustomFieldTypes, ErrorState } from "../components/OrderForm/Models/IOrder";
import { ICheckboxProps } from 'office-ui-fabric-react';

export class Common {

    public static validateFieldBasedOnType(fieldName: string, fieldType: CustomFieldTypes, isRequiredField: boolean, currentValue: any, errorState: ErrorState): ErrorState {
        switch (fieldType) {

            case CustomFieldTypes.Number:

                if (isRequiredField) {
                    errorState = this.requiredFieldValidatorNumericField(currentValue, fieldName, errorState);
                    if (errorState[fieldName].showError)
                        return errorState;
                }

                if (currentValue) {
                    if (!isNaN(currentValue as any)) {
                        if (isNaN(parseFloat(currentValue))) {
                            return this.setFieldValidationStateInvalid(fieldName, "Value should be numeric", errorState);
                        }
                        else if (currentValue.length > 10) {
                            return this.setFieldValidationStateInvalid(fieldName, "Value is too large.", errorState);
                        }
                        else {
                            return this.setFieldValidationStateValid(fieldName, errorState);
                        }
                    }
                    else {
                        return this.setFieldValidationStateInvalid(fieldName, "Value should be numeric", errorState);
                    }


                }
                else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }

            case CustomFieldTypes.Integer:

                if (isRequiredField) {
                    errorState = this.requiredFieldValidatorIntegerField(currentValue, fieldName, errorState);
                    if (errorState[fieldName].showError)
                        return errorState;
                }

                if (currentValue) {
                    if (!isNaN(currentValue as any)) {
                        if (Number.isInteger(parseFloat(currentValue))) {
                            return this.setFieldValidationStateValid(fieldName, errorState);
                        }
                        else if (parseFloat(currentValue) <= 0) {
                            return this.setFieldValidationStateInvalid(fieldName, "Please enter valid value.", errorState);
                        }
                        else if (currentValue.length > 10) {
                            return this.setFieldValidationStateInvalid(fieldName, "Value is too large.", errorState);
                        }
                        else {
                            return this.setFieldValidationStateInvalid(fieldName, "Only integer value is allowed", errorState);
                        }
                    }
                    else {
                        return this.setFieldValidationStateInvalid(fieldName, "Only integer value is allowed", errorState);
                    }

                }
                else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }

            case CustomFieldTypes.MultiUser:

                if (isRequiredField) {
                    if (currentValue && currentValue.length) {
                        return this.setFieldValidationStateValid(fieldName, errorState);
                    }
                    else {
                        return this.setFieldValidationStateInvalid(fieldName, "Required..", errorState);
                    }
                }
                else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }

            case CustomFieldTypes.User:

                if (isRequiredField) {
                    if (currentValue && currentValue.EMail) {
                        return this.setFieldValidationStateValid(fieldName, errorState);
                    }
                    else {
                        return this.setFieldValidationStateInvalid(fieldName, "Required..", errorState);
                    }
                }
                else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }

            case CustomFieldTypes.Email:
                if (isRequiredField) {
                    errorState = this.requiredFieldValidator(currentValue, fieldName, errorState);
                    if (errorState[fieldName].showError)
                        return errorState;
                }

                if (!this.validEmail(currentValue)) {
                    return this.setFieldValidationStateInvalid(fieldName, "Please specify valid E-Mail.", errorState);
                } else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }

            case CustomFieldTypes.File:

                if (isRequiredField) {
                    if (!currentValue || (currentValue as any[]).length <= 0) {
                        return this.setFieldValidationStateInvalid(fieldName, "Please upload attachment.", errorState);
                    }
                    else {
                        return this.setFieldValidationStateValid(fieldName, errorState);
                    }
                }
                else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }

            case CustomFieldTypes.MultiLine:

                if (isRequiredField) {
                    return this.requiredFieldValidatorMultiLineField(currentValue, fieldName, errorState);
                }
                else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }

            case CustomFieldTypes.DateTime:
            case CustomFieldTypes.Date:

                if (isRequiredField) {
                    if (currentValue) {
                        return this.setFieldValidationStateValid(fieldName, errorState);
                    }
                    else {
                        return this.setFieldValidationStateInvalid(fieldName, "Required..", errorState);
                    }

                }
                else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }

            default:
                if (isRequiredField) {
                    return this.requiredFieldValidator(currentValue, fieldName, errorState);
                }
                else {
                    return this.setFieldValidationStateValid(fieldName, errorState);
                }
        }

    }

    public static validEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public static requiredFieldValidator(value: string, fieldName: string, errorState: ErrorState): ErrorState {

        let errorMessage: string;
        let isValid: boolean;
        if (typeof value === 'boolean')
            value = String(value);

        if (value) {
            if (value.length > 0 && value.length <= 255) {
                isValid = true;
                errorMessage = "";
            }
            else if (value.length > 255) {
                isValid = false;
                errorMessage = "No. of characters should be less than 255.";
            }
            else {
                isValid = false;
                errorMessage = "Required..";
            }
        }
        else {
            isValid = false;
            errorMessage = "Required..";
        }

        errorState[fieldName] = {
            showError: !isValid,
            errorMessage: errorMessage
        };

        return errorState;
    }

    public static requiredFieldValidatorMultiLineField(value: string, fieldName: string, errorState: ErrorState): ErrorState {

        let errorMessage: string;
        let isValid: boolean;
        if (value) {
            if (value.length > 0) {
                isValid = true;
                errorMessage = "";
            }
            else {
                isValid = false;
                errorMessage = "Required..";
            }
        }
        else {
            isValid = false;
            errorMessage = "Required..";
        }

        errorState[fieldName] = {
            showError: !isValid,
            errorMessage: errorMessage
        };

        return errorState;
    }

    public static requiredFieldValidatorNumericField(value: string, fieldName: string, errorState: ErrorState): ErrorState {

        let errorMessage: string;
        let isValid: boolean;
        if (value) {
            if (!isNaN(value as any)) {
                if (isNaN(parseFloat(value))) {
                    isValid = false;
                    errorMessage = "Value should be numeric";
                }
                else if (value.length > 10) {
                    isValid = false;
                    errorMessage = "Value is too large.";
                }
                else {
                    isValid = true;
                    errorMessage = "";
                }
            }
            else {
                isValid = false;
                errorMessage = "Value should be numeric";
            }

        }
        else {
            isValid = false;
            errorMessage = "Required..";
        }

        errorState[fieldName] = {
            showError: !isValid,
            errorMessage: errorMessage
        };

        return errorState;
    }

    public static requiredFieldValidatorIntegerField(value: string, fieldName: string, errorState: ErrorState): ErrorState {

        let errorMessage: string;
        let isValid: boolean;
        if (value) {
            if (!isNaN(value as any)) {
                if (Number.isInteger(parseFloat(value))) {
                    isValid = true;
                    errorMessage = "";
                }
                else if (parseFloat(value) <= 0) {
                    isValid = false;
                    errorMessage = "Please enter valid value.";
                }
                else if (value.length > 10) {
                    isValid = false;
                    errorMessage = "Value is too large.";
                }
                else {
                    isValid = true;
                    errorMessage = "Only integer value is allowed";
                }
            }
            else {
                isValid = true;
                errorMessage = "Only integer value is allowed";
            }
        }
        else {
            isValid = false;
            errorMessage = "Required..";
        }

        errorState[fieldName] = {
            showError: !isValid,
            errorMessage: errorMessage
        };

        return errorState;
    }

    public static setFieldValidationStateInvalid(fieldName: string, errorMessage: string, errorState: ErrorState): ErrorState {

        errorState[fieldName] = {
            showError: true,
            errorMessage: errorMessage
        };

        return errorState;
    }

    public static setFieldValidationStateValid(fieldName: string, errorState: ErrorState): ErrorState {

        errorState[fieldName] = {
            showError: false,
            errorMessage: ''
        };

        return errorState;
    }

    public static renderCheckBoxlabel = (props?: ICheckboxProps, defaultRender?: (props?: ICheckboxProps) => JSX.Element | null): JSX.Element | null => {

        if (props && props.label) {

            return React.createElement("span", {
                className: 'labelCheckBox',
                dangerouslySetInnerHTML: {
                    __html: props && props.label ? props?.label : ''
                }
            })
        }
        else {
            return defaultRender ? defaultRender() : null;
        }
    }

    public static loadScript(scriptURL:string, callbackFunction?: Function) {
        const script = document.createElement("script");

        script.src = scriptURL;
        script.async = true;

        document.body.appendChild(script);
        if (callbackFunction) {
            script.onload = () => {
                callbackFunction();
            }
        }
        
    }

}