import * as React from 'react';
import { Stack, ActionButton } from 'office-ui-fabric-react';

const WizardNavigationButtons = (props: {
    currentStep?: any;
    firstStep?: any;
    goToStep?: any;
    lastStep?: any;
    nextStep?: any;
    previousStep?: any;
    totalSteps?: any;
    step?: any;
    sendToCustomer?: any;
    submitOrder?: any;
    isUserAuthenticated?: boolean;
}) => (
        <div>
            <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 20 }} wrap={true} className="fullWidth">
                {
                    props.step > 1 || props.step < props.totalSteps ?
                        <div className="actionButtonsNavigationBar">
                            {
                                props.step > 1 && <ActionButton className="wizardButton" iconProps={{ iconName: 'ChevronLeft' }} onClick={props.previousStep}>Go Back</ActionButton>
                            }
                            {
                                props.step < props.totalSteps -1 && <ActionButton className="wizardButton" iconProps={{ iconName: 'ChevronRight' }} onClick={props.nextStep}>Next</ActionButton>
                            }
                        </div>
                        : null
                }
                {
                    props.step === props.totalSteps || props.step === 4 ?
                        <div className="actionButtonsNavigationBar">
                            {
                                props.step === 4 &&
                                <React.Fragment>
                                    {
                                        props.isUserAuthenticated ? <ActionButton className="wizardButton" iconProps={{ iconName: 'Assign' }} onClick={props.sendToCustomer}>Send To Customer</ActionButton> : null
                                    }
                                    <ActionButton className="wizardButton" iconProps={{ iconName: 'Assign' }} onClick={props.nextStep}>Sign Order</ActionButton>
                                </React.Fragment>
                            }
                            {
                                props.step === props.totalSteps && <ActionButton className="straumannBlueButton" iconProps={{ iconName: 'Assign' }} onClick={props.submitOrder}>Submit Order</ActionButton>
                            }
                        </div>
                        : null
                }
            </Stack>
        </div>
    );

export default WizardNavigationButtons;